import { useState, useEffect, useRef } from 'react';


export const useNoInitialRenderEffect = (cb: () => void, dep?: any[]) => {
    const [hasComponentRenderedOnce, setHasComponentRenderedOnce] = useState(false);

    const initialRender = useRef(true);
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            setHasComponentRenderedOnce(true);
        }

        if (hasComponentRenderedOnce) {
            cb();
        }
    }, dep);
}

