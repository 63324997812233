import React from "react";
import {useState} from 'react';

const useToggleable = (initialToggled: boolean, onToggle: (toggled: boolean) => void) => {
  const [ toggled, setToggled ] = useState(initialToggled);

  const toggle = () => {
    const newToggled = !toggled;
    setToggled(newToggled);
    onToggle(newToggled);
    return newToggled;
  };

  const manualSetToggled = (state: boolean) => {
    // If we're not changing the state, don't fire an onToggle event
    if (state !== toggled) {
      toggle();
    }
  };

  const onDoubleClick = () => {
    toggle();
  };

  const onClick = () => {
    toggle();
  };

  // Take a function and return a function
  const onToggleClick = (onToggle: (toggle: boolean) => void) => () => {
    const newToggled = toggle();
    return onToggle(newToggled);
  };

  return {
    toggled,
    toggle,
    setToggled: manualSetToggled,
    onDoubleClick,
    onClick,
    onToggleClick
  }
};

interface ToggleButtonProps {
  onToggle: (toggled: boolean) => void;
  title: string;
  icon: string;
  initialToggled: boolean;
}

const ToggleButton = ({onToggle, title, icon, initialToggled}: ToggleButtonProps) => {
  const initToggled = initialToggled !== undefined ? initialToggled : false;
  const { toggled, onClick } = useToggleable(initToggled, onToggle);
  const baseClassName = `button icon-${icon}`;
  const className = toggled ? baseClassName + ' button--toggled' : baseClassName; // Use undefined here, even though the types don't match, so that child component knows it has no extraClass
  return <div data-testid="toggleButton" role='button' title={title} onClick={onClick} className={className} />;
}

export {
  ToggleButton
};
