import React, { useContext } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { Auth } from '../../auth/Auth';
import AppContext from '../../context/AppContext';

interface ProtectedRouteProps {
  children: JSX.Element | JSX.Element[];
  path: string;
  exact: boolean;
}

const ProtectedRoute = ({ children, path, exact }: ProtectedRouteProps) => {
  const { appIsPersistingUrl } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();

  const takeUserToUrlIfRequestedBeforeAuthentication = () => {
    const grid_url = localStorage.getItem('grid_url');
    localStorage.removeItem('grid_url');
    if (grid_url) {
      history.push(grid_url);
    }
  }

  return (
    <Route path={path} exact={exact} render={() => {
      if (Auth.isAuthenticated()) {
        takeUserToUrlIfRequestedBeforeAuthentication();
        return <>{children}</>;
      } else {
        const isChildPathOfDashboard: boolean = location.pathname.split('/').length > 2;
        if (appIsPersistingUrl.get() && isChildPathOfDashboard) {
          localStorage.setItem('grid_url', location.pathname);
        }
        return <Redirect to='/' />
      }
    }
    } />
  )
}

export {
  ProtectedRoute
};