export enum SaveForEnum {
	Myself,
	Others
}

export interface Favorite {
  Id: string;
  Name: string;
  Customisation: any;
  CreatedAt: Date;
  SaveFor: SaveForEnum;
}

export interface ModelView {
  display_name: string,
  datasource_api: string,
  datasource_handle: string,
  attributes: any[],
  view_type?: string,
  asset_type_uuid?: string,
  description?: string,
  view_uuid?: string,
  view_handle: string,
  tags?: string[],
  enable_custom_attributes?: boolean,
  enable_geospatial_support?: boolean,
  crs_code?: string,
  shape_type?: string,
  navigations?: any[],
  favorites?: Favorite[],
  kml_style?: string,
  enable_cache?: boolean,
  cache_ttl?: number,
  created_at?: string,
  updated_at?: string,
  client_list?: any[],
  asset_type?: any,
  asset_geospatial_styles?: any[],
  detail_views?: any[],
  gis_placemark_style_column?: string,
  gis_placemark_name_column?: string,
  created_by: string;
  updated_by: string;
}

const appendAccumulatorCategory = (acc: Record<string, ModelView[]>, tag: string, view: ModelView) => {
  const oldValue = acc.hasOwnProperty(tag) ? acc[tag] : [];
  const newValue = oldValue.concat([view]);
  return {
    ...acc,
    [tag]: newValue
  };
};

const appendAccumulatorTags = (acc: Record<string, ModelView[]>, view: ModelView) => {
  // TODO: Need to localize string 'Other', find a solution for I18N within code.
  // Recommend .json object files whose keys map to template strings, defaulted to American english.
  return view.tags === null || view.tags === undefined ? appendAccumulatorCategory(acc, 'Other', view) : 
    view.tags.reduce((acc, tag: string) => {
      return appendAccumulatorCategory(acc, tag, view);
    }, acc);
};

const categorizeViews = (views: ModelView[]) => {
  return views.reduce((acc: Record<string, ModelView[]>, view: ModelView) => {
    return appendAccumulatorTags(acc, view);
  }, {
    Other: [] as ModelView[]
  });
};

const getUniqueIdentifierColumn = (view: ModelView) => {
  const selfKeys = view.attributes.filter((item: any) => item.self_key) as any[];
  // name of the first self_key column is considered as the unique identifier column of the grid
  return (selfKeys.length > 0) ? selfKeys[0].name : null;
}

const getColumnNameFromViewDef = (attr: any, value: any, view: any) => {
  return view.attributes.find((item: any) => {
    return item[attr] === value;
  }).name;
}

const excluded = 'Excluded';
const hidden = 'Hidden';
const isExcludedAttribute = (value: string) => value === excluded;
const isHiddenAttribute = (value: string) => value === hidden;

const View = {
  categorizeViews,
  isExcludedAttribute,
  isHiddenAttribute,
  getUniqueIdentifierColumn,
  getColumnNameFromViewDef
};

export default View;
