import { mqtt, iot } from 'aws-iot-device-sdk-v2';
import {RWActionTypes, RW_IOT} from '../constants';
import {ServiceBase} from './ServiceBase';
import {v4} from 'uuid';

interface RoamesWorldIOTPublish {
	MessageId: string;
	Action: string;
	Parameters: {}
}

let iamCredentials: any = null;

const generateIoTUniqueId = () => {
	return `roames_web-${v4()}`;
} // IoT client Id has to be unique, so using uuid.v4()

const AWS_IOT_REGION: string = `${process.env.REACT_APP_IOT_REGION}`;
const AWS_IOT_ENDPOINT: string = `${process.env.REACT_APP_IOT_ENDPOINT}`;
const AWS_IOT_CONNECTION_CLIENT_ID: string = generateIoTUniqueId();
const AWS_IOT_AUTH_URL: string = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/delegation`;

const getIAMCredentials = async (token: string, body: any) => {

	return ServiceBase.authPost(AWS_IOT_AUTH_URL, token, '*/*', body);
	
}

const connectToAWSIOT = async (token: string, connection: any): Promise<mqtt.MqttClientConnection> => {
	return new Promise(async (resolve, reject) => {
		const payload = {
			grant_type: process.env.REACT_APP_IOT_GRANT_TYPE,
			client_id: process.env.REACT_APP_IOT_CLIENT_ID,
			target: process.env.REACT_APP_IOT_TARGET,
			api_type: process.env.REACT_APP_IOT_API_TYPE,
			role: process.env.REACT_APP_IOT_ROLE,
			principal: process.env.REACT_APP_IOT_PRINCIPAL,
			id_token: token
		}

		iamCredentials = await getIAMCredentials(token, JSON.stringify(payload));

		const config_builder: iot.AwsIotMqttConnectionConfigBuilder = iot.AwsIotMqttConnectionConfigBuilder.new_with_websockets({
			region: AWS_IOT_REGION,
			proxy_options: undefined,
			credentials_provider: undefined as any
		});

		config_builder.with_clean_session(false);
		config_builder.with_client_id(AWS_IOT_CONNECTION_CLIENT_ID); // this client_id is different to Auth0 client_id used above!
		config_builder.with_endpoint(AWS_IOT_ENDPOINT);
		config_builder.with_credentials(AWS_IOT_REGION, iamCredentials.Credentials.AccessKeyId, iamCredentials.Credentials.SecretAccessKey, iamCredentials.Credentials.SessionToken);

		const client = new mqtt.MqttClient();

		const config: mqtt.MqttConnectionConfig = config_builder.build();
		connection = client.new_connection(config);

		try {
			connection.connect();
			resolve(connection);
		} catch (error) {
			reject(error)
		}

	})
}


const init = async (token: string | undefined, setRWUserOnlineStatus: (isRWUserOnline: boolean) => void, connection: mqtt.MqttClientConnection | null, setIotConn: any): Promise<mqtt.MqttClientConnection> => {
	let iotConnection: mqtt.MqttClientConnection;

	const on_presence = (topic: any, payload: any) => {
		const decoder = new TextDecoder('utf8');
		const json = decoder.decode(payload);
		const message = JSON.parse(json);
		const isRWUserDisconnected = message.eventType === 'disconnected';
		setRWUserOnlineStatus(!isRWUserDisconnected);
	}

	const onAckReceive = (topic: string, payload: ArrayBuffer) => {
		setRWUserOnlineStatus(true);
	}

	return new Promise(async (resolve, reject) => {
		if (!token) return
		try {
			iotConnection = await connectToAWSIOT(token, connection);
			setIotConn(iotConnection);
		} catch (error) {
			reject(error)
		}

		try {
			// subscribe to the Acknowledgment messages dispatched by RW
			await subscribe(`user/${iamCredentials.Subject}/${RW_IOT.ACKNOWLEDGE_TOPIC_SUFFIX}`, onAckReceive, iotConnection)

		} catch (error) {
			reject(error)
		}

		try {
			// Tell IOT Core that user on Data grids is online
			await publish({ClientId: AWS_IOT_CONNECTION_CLIENT_ID}, RWActionTypes.Presence, iotConnection);
		} catch (error) {
			reject(error);
		}

		try {
			// subscribe to online status of RW
			let email = iamCredentials.Subject.split('|')[1]
			await subscribe("$aws/events/presence/+/" + email, on_presence, iotConnection);
		} catch (error) {
			reject(error);
		}
		resolve(iotConnection)
	})
}

const publish = ((params: any, action: RWActionTypes, connection: mqtt.MqttClientConnection) => {
	let message: RoamesWorldIOTPublish = {
		MessageId: generateIoTUniqueId(),
		Action: action,
		Parameters: params
	}
	return connection.publish(`user/${iamCredentials.Subject}/${RW_IOT.ACTION_TOPIC_SUFFIX}`, JSON.stringify(message), mqtt.QoS.AtLeastOnce);
})

const subscribe = (topic: string, callback: (topic: string, payload: any) => void, connection: mqtt.MqttClientConnection) => {
	return connection.subscribe(topic, mqtt.QoS.AtLeastOnce, callback);
}

const unsubscribe = (connection: mqtt.MqttClientConnection | undefined) => {
	connection?.disconnect();
}

export const IoTService = {
	init,
	publish,
	unsubscribe
}
