import React from 'react';
import { isNullOrUndefined, cleanString, stringStitcher } from '../../../utils/helper';

export interface Profile {
  picture: string;
  roles: string[];
  given_name: string;
  family_name: string;
  email: string;
  name: string;
}
interface UserProps {
  profile: Profile | undefined;
  expanded: boolean;
}

const User = ({ profile, expanded }: UserProps) => {
  const constructUserName = (profile: Profile) => {
    const name = profile.name;
    const nameList = [cleanString(profile.given_name), cleanString(profile.family_name)];
    const defaultName = 'Unknown User';

    if (name) {
      return name;
    } else if (!isNullOrUndefined(profile.given_name) && !isNullOrUndefined(profile.family_name)) {
      return stringStitcher(nameList, ' ');
    } else {
      return defaultName;
    }
  }
  
  if (profile !== undefined) {
    const styles = {
      backgroundImage: `url(${profile.picture})` // TODO: Get profile picture from azure
    };

    const role = profile.roles.length > 0 ? profile.roles[0] : '';
    
    const username = constructUserName(profile);
    return <div className='user'>
      <div className='user__photo' style={styles} />
      {
        expanded ? <div className='user__details'>
          <div className='user__name' title={profile.email}>{username}</div>
          {role ? <div className='user__title'>{role}</div> : <></>}
        </div> : <></>
      }
    </div>;
  } else {
    return <></>;
  }


};

export {
  User
};
