import React from 'react';

interface LogoProps {
  expanded: boolean;
  toggleExpanded: () => void;
}

const Logo = ({expanded, toggleExpanded}: LogoProps) => {
  // TODO: Find a better way that works well with exporting this as a library
  return <div className="logo">
    <div className='logo__logo' />
    <div className="logo__toggle" onClick={toggleExpanded}>
      {
        expanded ? <div className="icon-navigate_before"></div> : <div className="icon-navigate_next"></div>
      }
    </div>
  </div>
};

export { 
  Logo
};
