import React, { MutableRefObject, RefObject, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Favorite, ModelView, SaveForEnum } from '../../../model/View';
import { v4 as uuidv4 } from 'uuid';
import { ViewDataService } from '../../../service/ViewDataService';
import { isNullOrUndefined } from '../../../utils/helper';
import { AgGridReact } from 'ag-grid-react';
import { GridImplementation } from '../GridImplementation';

export interface FavoriteDetails {
  name: string;
  saveFor: number;
}

interface FavouriteDialogAttrs {
  open: boolean;
  editing: boolean;
}

export interface IFavoriteDialogRef {
  setFavoriteDialogAttrs: React.Dispatch<FavouriteDialogAttrs>
};

interface FavoriteDialogProps {
  favouriteDialogRef: MutableRefObject<IFavoriteDialogRef | null>;
  selectedFavorite: Favorite | null;
  setFavorite: (favorite: Favorite | null) => void;
  setSelectedFavorite: (favorite: Favorite | null) => void;
  gridRef: RefObject<AgGridReact<any>>;
  view: ModelView;
  setView: (view: ModelView) => void;
  token: string;
}

const defaultFavoriteName = '';
const defaultSaveFor = SaveForEnum.Others;
const defaultFieldErrorMessage = '';
const minimumFavoriteNameLength = 1;

const getValidationErrorMessage = (name: string): string | null => {
  if (name.length < minimumFavoriteNameLength) {
    return `Favorite name must be at least ${minimumFavoriteNameLength} character long.`;
  } else {
    return null;
  }
};

const isValidName = (name: string) => {
  return getValidationErrorMessage(name) === null;
};

const FavoriteDialog = ({ favouriteDialogRef, selectedFavorite, setFavorite, setSelectedFavorite, gridRef, view, setView, token }: FavoriteDialogProps) => {
  const [favoriteDialogAttrs, setFavoriteDialogAttrs] = useState<any>({ open: false, editing: false });

  favouriteDialogRef.current = { setFavoriteDialogAttrs };

  const createFavorite = (details: FavoriteDetails) => {
    if (gridRef.current !== null) {
      const columnState = gridRef.current.columnApi.getColumnState();
      const sortModel = GridImplementation.getSortModelFromColumnState(columnState);
      // Create a new favorite
      const favorite = {
        Id: uuidv4(),
        Name: details.name,
        Customisation: {
          sort: sortModel,
          filter: gridRef.current.api.getFilterModel(),
          columnState
        },
        CreatedAt: new Date(),
        SaveFor: details.saveFor,
      };
      const favorites = view.favorites !== undefined ? view.favorites.concat([favorite]) : [favorite];
      const newView = {
        ...view,
        favorites
      };
      ViewDataService.updateView(token, newView).then(() => {
        setView(newView);
        setSelectedFavorite(favorite);
      });
    }
  };

  const editFavorite = (details: FavoriteDetails) => {
    if (selectedFavorite !== null && gridRef.current !== null) {
      // Make types happy
      if (!isNullOrUndefined(view.favorites)) {
        const favoriteIndex = view.favorites.findIndex((favorite: any) => {
          return favorite.Id === selectedFavorite.Id;
        });
        // Sanity check
        if (favoriteIndex > -1) {
          // Copy the array before the edited favorite
          const beforeFavorites = view.favorites.slice(0, favoriteIndex);
          // Copy the array after the edited favorite
          const afterFavorites = view.favorites.slice(favoriteIndex + 1, view.favorites.length);
          const columnState = gridRef.current.columnApi.getColumnState();
          const sortModel = GridImplementation.getSortModelFromColumnState(columnState);
          // Create a new favorite out of the old one
          const editedFavorite = {
            ...view.favorites[favoriteIndex],
            Name: details.name,
            SaveFor: details.saveFor,
            Customisation: {
              sort: sortModel,
              filter: gridRef.current.api.getFilterModel(),
              columnState
            }
          };
          const favorites = beforeFavorites.concat([editedFavorite]).concat(afterFavorites);
          const newView = {
            ...view,
            favorites
          };
          ViewDataService.updateView(token, newView).then(() => {
            setView(newView);
            setSelectedFavorite(editedFavorite);
          });
        }
      }
    }
  };

  const deleteFavorite = (favorite: Favorite) => {
    if (!isNullOrUndefined(view.favorites)) {
      const favoriteIndex = view.favorites.findIndex((viewFavorite: Favorite) => {
        return favorite.Id === viewFavorite.Id;
      });
      // Sanity check
      if (favoriteIndex > -1) {
        // Copy the array before the edited favorite
        const beforeFavorites = view.favorites.slice(0, favoriteIndex);
        // Copy the array after the edited favorite
        const afterFavorites = view.favorites.slice(favoriteIndex + 1, view.favorites.length);
        const favorites = beforeFavorites.concat(afterFavorites);
        const newView = {
          ...view,
          favorites
        };
        ViewDataService.updateView(token, newView).then(() => {
          setView(newView);
          setFavorite(null);
        });
      }
    }
  };

  const [favoriteName, setFavoriteName] = useState(defaultFavoriteName);
  const [saveFor, setSaveFor] = useState(defaultSaveFor);
  const [fieldErrorMessage, setFieldErrorMessage] = useState('');

  const setClosed = () => {
    const newAttrs = { open: false, editing: false };
    setFavoriteName(defaultFavoriteName);
    setSaveFor(defaultSaveFor);
    setFieldErrorMessage(defaultFieldErrorMessage);
    setFavoriteDialogAttrs(newAttrs);
  };

  const onClose = () => {
    setClosed();
  };

  const onEnter = () => {
    if (favoriteDialogAttrs.editing && selectedFavorite !== null) {
      setFavoriteName(selectedFavorite.Name);
      setSaveFor(selectedFavorite.SaveFor);
      setFieldErrorMessage(defaultFieldErrorMessage);
    }
  };

  const onSubmit = () => {
    if (isValidName(favoriteName)) {
      const details = {
        name: favoriteName,
        saveFor
      };

      if (favoriteDialogAttrs.editing) {
        editFavorite(details);
      } else {
        createFavorite(details);
      }
      onClose();
    }
  };

  const onDelete = (favorite: Favorite) => {
    deleteFavorite(favorite);
    onClose();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const errorMessage = getValidationErrorMessage(value)
    if (errorMessage !== null) {
      setFieldErrorMessage(errorMessage);
    } else {
      setFieldErrorMessage('');
    }
    // It may seem counterintuitive to set the favorite name here after we've done validation, regardless of whether that validation passed or not...
    // but not allowing the user to make a mistake here feels like you're taking control away from the user and that's bad ux
    // We can still prevent the user from submitting the form later, which achieves the same thing anyway
    setFavoriteName(value);
  };

  // const handleSaveForMyselfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // Has to be this way due to radioGroup on change coming through as a string.
  //   const numValue = parseInt(event.target.value, 10);
  //   setSaveFor(numValue);
  // };

  const title = `${favoriteDialogAttrs.editing ? 'Edit' : 'Create'} a Favorite`;

  return (
    <Dialog open={favoriteDialogAttrs.open} onClose={onClose} TransitionProps={{onEntered: onEnter}} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter Favorite name</DialogContentText>
        <TextField
          error={fieldErrorMessage !== ''}
          autoFocus
          margin="dense"
          id="name"
          label="Favorite Name"
          type="text"
          fullWidth
          value={favoriteName}
          helperText={fieldErrorMessage}
          onChange={handleNameChange}
        />
        {/*
        <FormLabel component="span">Save For: </FormLabel>
        // TODO: Work out what is going on with the customization service
        <RadioGroup aria-label="anonymous" name="anonymous" value={saveFor} row onChange={handleSaveForMyselfChange}>
          <FormControlLabel value={SaveForEnum.Myself} control={<Radio checked={saveFor === SaveForEnum.Myself} />} label="Only Myself" />
          <FormControlLabel value={SaveForEnum.Others} control={<Radio checked={saveFor === SaveForEnum.Others} />} label="Others" />
        </RadioGroup> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        {
          selectedFavorite !== null && favoriteDialogAttrs.editing ?
            <Button onClick={() => { onDelete(selectedFavorite) }} color="primary">Delete</Button>
            : <></>
        }
        <Button onClick={onSubmit} color="primary">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export {
  FavoriteDialog
}
