import { MEDIA_TYPE } from "../constants";
export interface IError {
  statusCode: number;
  message: string;
  error: string;
}

// TODO: Generic type this properly instead of using any in Promise
const authService = (url: string, token: string, acceptType: string, resolve: (args: any) => any, reject: (args: any) => any, method: string, body?: any, contentType?: string, logOut?: () => void | undefined): Promise<any> => {
  const headers = new Headers({
    'Authorization': 'Bearer ' + token,
    'Accept': acceptType,
    'Content-type': contentType ? contentType : 'application/json'
  });

  const maybeBody = body ? { body } : {};
  const request = {
    method,
    headers,
    ...maybeBody
  };

  return fetch(url, request)
    .then(handleErrors)
    .then((response: Response) => {
      let res: Promise<any>;
      if (acceptType === MEDIA_TYPE.CSV || acceptType === MEDIA_TYPE.KML) {
        res = response.text()
      } else {
        res = response.json()
      }
      resolve(res)
    })
    .catch(((e: Error) => {
      try {
        let error: IError = JSON.parse(e.message)
        if (error.statusCode === 401) {
          error.message = 'Please login again';
          if (logOut) {
            logOut();
          }
        }
        reject(error)
      } catch {
        reject(e);
      }
    }));
};

/*
Question: Why do we explicitly check response for errors returned by fetch()? 
Answer: 
The Promise returned from fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500. Instead, it
will resolve normally (with ok status set to false), and it will only reject on network failure or if
anything prevented the request from completing (Copied from MDN Docs)*/
const handleErrors = async (response: Response) => {
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return response;
}

const authGet = (url: string, token: string, contentType: string, logOut: () => void) => {
  return new Promise((resolve, reject) => {
    return authService(url, token, contentType, resolve, reject, 'GET', undefined, undefined, logOut);
  });
};

const authPost = (url: string, token: string, contentType: string, content: string) => {
  return new Promise((resolve, reject) => {
    return authService(url, token, contentType, resolve, reject, 'POST', content);
  });
};

const authPut = (url: string, token: string, contentType: string, content: string) => {
  return new Promise((resolve, reject) => {
    return authService(url, token, contentType, resolve, reject, 'PUT', content);
  });
};

export const ServiceBase = {
  authGet,
  authPost,
  authPut
};