import React from "react";
import {
    CellClassParams,
    ColDef,
    ColGroupDef,
    ValueFormatterParams
} from 'ag-grid-enterprise';
import View, { ModelView } from "../../model/View";
import { isNullOrUndefined, getDateStringAsPerLocale, getDateTimeStringAsPerLocale } from "../../utils/helper";
import { GridFiltering } from "./GridFiltering";
import { GridFormatters } from './GridFormatters';

export const numeric_types = ["Integer", "Float", "Long"];

const getHeaderTooltip = (attribute: any) => {
    const description = attribute.description ? attribute.description : attribute.display_name;
    const units = isNullOrUndefined(attribute.request_units)
        ? ""
        : ` units: ${attribute.request_units} `;
    return `${description} (type: ${attribute.primitive_type}${units})`;
};

const attributeIsHidden = (attribute: any): boolean => {
    return View.isExcludedAttribute(attribute.display) || View.isHiddenAttribute(attribute.display);
};

const extractAttrAsColDef = (attribute: any, token: string, view: ModelView, logOut: () => void): ColDef => {
    const headerTooltip = getHeaderTooltip(attribute);
    const filter = GridFiltering.getAttributeFilter(token, view, attribute, logOut);
    const editable = attribute.editable && (attribute.editable === true || attribute.editable.toLowerCase() === 'true');

    let valueFormatter = undefined;
    let cellStyle = undefined;

    if (numeric_types.indexOf(attribute.primitive_type) > -1) {
        const cellFormatter = GridFormatters.cell_formatters.find(pred => pred.name === attribute.formatter_name);
        valueFormatter = cellFormatter ? (params: ValueFormatterParams) => {
            return params.value ? cellFormatter.formatter_function(params.value) : params.value;
        } : undefined;
        cellStyle = { 
            textAlign: "right" 
        };
    } else if (attribute.primitive_type === 'Date') {
        valueFormatter = (params: ValueFormatterParams) => {
            return params.value ? getDateStringAsPerLocale(params.value) : params.value;
        };
    } else if (attribute.primitive_type === 'Date/Time') {
        valueFormatter = (params: ValueFormatterParams) => {
            return params.value ? getDateTimeStringAsPerLocale(params.value) : params.value;
        };
    };

    // Use unicode pencil instead of trying to manipulate ag-grid to show one itself
    const headerName = `${attribute.display_name}${editable ? ' ✎' : ''}`;

    const isEditableBoolean = editable && attribute.primitive_type.toLowerCase() === "boolean";

    const cellRenderer = isEditableBoolean ? (params: any) => {
        const checked = params.value === "true" || params.value === true;
        return <input type="checkbox" checked={checked}></input>
    } : undefined;

    const cellEditor = isEditableBoolean ? 'agSelectCellEditor' : undefined;
    const cellEditorParams = isEditableBoolean ? {
        values: ['true', 'false']
    } : undefined;

    return {
        field: attribute.name,
        headerName,
        hide: attributeIsHidden(attribute),
        headerTooltip,
        resizable: true,
        valueFormatter,
        cellStyle,
        editable,
        cellRenderer,
        cellEditor,
        cellEditorParams,
        ...filter
    };
};

const getAppendAttr = (token: string, view: ModelView, logOut: () => void) => (acc: (ColDef | ColGroupDef)[], attribute: any) => {
    const appendGroups = (acc: (ColDef | ColGroupDef)[], group_name: any) => {
        const currentGroupIndex = acc.findIndex((groupOrCol) => {
            return groupOrCol.hasOwnProperty('headerName') && groupOrCol.headerName === group_name;
        });
        if (currentGroupIndex > -1) {
            const beforeCurrentGroup = acc.slice(0, currentGroupIndex);
            const currentGroup = acc[currentGroupIndex] as ColGroupDef;
            const newGroupChildren = currentGroup.children.concat([extractAttrAsColDef(attribute, token, view, logOut)]);
            const newGroup = [{
                ...currentGroup,
                children: newGroupChildren
            }];
            const afterCurrentGroup = acc.slice(currentGroupIndex + 1, acc.length);
            return beforeCurrentGroup.concat(newGroup).concat(afterCurrentGroup);
        } else {
            return acc.concat([{
                headerName: group_name,
                children: [extractAttrAsColDef(attribute, token, view, logOut)],
                marryChildren: true
            }])
        }
    };
    // TODO: Is this necessary? Will our users rely on it?
    // I'm just copying from the source
    if (View.isExcludedAttribute(attribute.display)) {
        // console.warn('buildColumnDefs :: Excluded attribute "' + attribute.name + '" present in custom attributes.  Temporarily hidden.');
        return acc; // Skip this column so return the accumulator
    }

    if (attribute.hasOwnProperty('group_names') && attribute.group_names.length > 0) {
        return attribute.group_names.reduce(appendGroups, acc);
    } else {
        return acc.concat([extractAttrAsColDef(attribute, token, view, logOut)]);
    }
};

const extractAttributesAsColDefs = (token: string, view: ModelView, logOut: () => void): (ColDef | ColGroupDef)[] => {
    view.attributes.sort(function (a, b) {
        return a.order - b.order;
    });

    const appendAttr = getAppendAttr(token, view, logOut);

    return view.attributes.reduce(appendAttr, []);
};

const buildColDefs = (token: string, view: ModelView, getActiveRowsIndex: () => number, logOut: () => void): ColDef[] => {
    const cellStyleHandler = (params: CellClassParams) => {
        if (params.node.rowIndex === getActiveRowsIndex()) {
            return { background: "#3FA0D4" };
        }
    };

    const selectionColumn = {
        field: 'select',
        headerName: 'Select',
        sortable: false,
        suppressMenu: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        lockPinned: true,
        pinned: 'left',
        lockPosition: true,
        resizable: true,
        cellStyle: cellStyleHandler
    } as ColDef;

    return [selectionColumn].concat(extractAttributesAsColDefs(token, view, logOut));
};

const GridAttributes = {
    buildColDefs
};

export {
    GridAttributes
}