import React from "react";
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';

interface PageTopFilterProps {
  id?: string;
  label: string;
  open: boolean;
  loading: boolean;
  values: { value: string | null, name: string }[];
  selected: { value: string | null, name: string }[];
  onOpen: () => void;
  onClose: () => void;
  onChange: (event: any, values: any[]) => void;
}

const PageTopFilter = ({
  id,
  label,
  open,
  loading,
  values,
  selected,
  onOpen,
  onClose,
  onChange,
}: PageTopFilterProps) => {
  return <Autocomplete
    id={id}
    className={'pagetopFilter'}
    multiple
    disableCloseOnSelect={true}
    style={{
      width: 200,
      marginRight: 10
    }}
    size='small'
    open={open}
    onOpen={onOpen}
    onClose={onClose}
    getOptionLabel={(option) => option.name}
    onChange={onChange}
    options={values}
    loading={loading}
    isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
    renderTags={() => {
      return <div>Count: {selected.length}</div>;
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        className={'pagetopFilter-selected'}
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    )}
    value={selected}
  />
};

export {
  PageTopFilter
};