import React, { RefObject } from "react";
import { ModelView } from "../../model/View";
import { PastNavigation } from "./PastNavigations";
import Button from "@mui/material/Button";
import { GridImplementation } from "./GridImplementation";
import { AgGridReact } from "ag-grid-react";

const getHandleNavigation = (
  gridRef: RefObject<AgGridReact<any>>,
  view: ModelView,
  views: any,
  navigation: any,
  navigations: PastNavigation[],
  navigateToView: (navs: any[]) => void
) => () => {
  if (gridRef.current !== null) {
    const filterModel = gridRef.current.api.getFilterModel();
    const columnState = gridRef.current.columnApi.getColumnState();
    const sortModel = GridImplementation.getSortModelFromColumnState(columnState);
    const previousState = {
      view,
      filterModel,
      sortModel,
      columnState,
    };
    const selectedRows = gridRef.current.api.getSelectedRows();

    const preFilter = navigation.mappers.reduce((acc: any, current: any) => {
      const mapped = selectedRows.map((selectedRow: any) => {
        return selectedRow[current.source_attribute];
      });
      const unique = mapped.filter(
        (value: any, index: number, self: any) => self.indexOf(value) === index
      );
      return {
        ...acc,
        [current.target_attribute]: unique,
      };
    }, {});

    const newView = views.uncategorizedViews.find((view: ModelView) => {
      return view.view_handle === navigation.target_view;
    });

    const newNavigations = navigations.concat([
      {
        previousState,
        view: newView,
        preFilter,
      },
    ]);
    navigateToView(newNavigations);
  }
};

interface AvailableNavigationsProps {
  view: ModelView | undefined;
  views: any;
  gridRef: RefObject<AgGridReact<any>>;
  navigations: PastNavigation[];
  navigateToView: (navigations: any[]) => void;
}

const AvailableNavigations = ({
  view,
  views,
  gridRef,
  navigations,
  navigateToView,
}: AvailableNavigationsProps) => {
  return (
    <div className="availablenavigations">
      {view?.navigations !== undefined ? (
        view?.navigations.map((navigation: any, i: number) => {
          return (
            <Button
              className="navigation-button"
              key={i}
              onClick={getHandleNavigation(
                gridRef,
                view,
                views,
                navigation,
                navigations,
                navigateToView
              )}
              color="primary"
            >
              <div className="navigation-textBox">
                <div className="navigation-text">
                  <div className="navigation-text-ellipsis">
                    {navigation.title}
                  </div>
                </div>
                <div className="navigation-icon icon-arrow_down" />
              </div>
            </Button>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export { AvailableNavigations };
