import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { FilterModel } from "./GridFiltering";

interface CurrentCycleSelectorProps {
  filterModel: FilterModel | null;
  updateFilterModel: (attrName: string, selected: string[] | undefined) => void;
}

const title = "Displays the most recent available data about all objects/assets detected, e.g. if we have 10,000 data records for cycle #2 fully processed and cycle #3 is in progress with 25% of objects/assets reprocessed, the table below will display 10,000 records where 7,500 records (75%) belong to cycle #2 and 2,500 records (25%) belong to cycle #3";

const CurrentCycleSelector = ({filterModel, updateFilterModel}: CurrentCycleSelectorProps) => {
  const currentCycle = filterModel !== null ? filterModel.cycle_current_ind?.values?.length === 1 &&
                        filterModel.cycle_current_ind.values[0] === 'true' : true;

  const toggleChecked = () => {
    const newCurrentCycle = !currentCycle;
    const value = newCurrentCycle ? ['true'] : ['true', 'false'];
    updateFilterModel('cycle_current_ind', value);
  };

  return <FormControlLabel
      className='fugro-current-cycle-selector'
      control={<Switch checked={currentCycle} onChange={toggleChecked} title={title} />}
      label="Most Recent Data"
      title={title}
    />
};

export {
  CurrentCycleSelector
}