import React, { MutableRefObject, RefObject, useState, Dispatch, SetStateAction, useEffect, useContext } from "react";
import { ToggleButton } from "../common/ToggleButton";

import Tooltip from "@mui/material/Tooltip";
import { PaginationControls } from "./Pagination";
import { RWNavigation } from "./RWNavigation";
import { TOOLTIP_TIMING } from "../../constants";
import { AgGridReact } from "ag-grid-react";
import { GridImplementation } from "./GridImplementation";
import { ModelView } from "../../model/View";
import { ToastAttributes } from "../common/Toast";
import { useNoInitialRenderEffect } from "../../hooks/useNoInitialRenderEffect";
import AppContext from "../../context/AppContext";
import { Button } from "@mui/material";

export interface IToolboxRef {
  totalRowCount: number;
  activeRowsIndex: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setTotalPageCount: Dispatch<SetStateAction<number>>;
  setFirstDisplayedRow: Dispatch<SetStateAction<number>>;
  setLastDisplayedRow: Dispatch<SetStateAction<number>>;
  setTotalRowCount: Dispatch<SetStateAction<number>>;
  setTotalSelectedRowCount: Dispatch<SetStateAction<number>>;
  rwNavigateToRow: (index: number) => void;
};

interface ToolboxProps {
  gridRef: RefObject<AgGridReact<any>>;
  toolboxRef: MutableRefObject<IToolboxRef | null>;
  token: string;
  view: ModelView;
  pagination: boolean;
  setPagination: (pagination: boolean) => void;
  setToast: (args: ToastAttributes) => void;
  logOut: () => void;
}

const Toolbox = ({
  gridRef,
  toolboxRef,
  token,
  view,
  pagination,
  setPagination,
  setToast,
  logOut
}: ToolboxProps) => {
  const { appIoTConnection, appIsRWUserOnline } = useContext(AppContext);

  const iotConnection = appIoTConnection.get();
  const isRWUserOnline = appIsRWUserOnline.get();

  const [pageNumber, setPageNumber] = useState(0); //current page number if pagination is active
  const [totalPageCount, setTotalPageCount] = useState(0); //current page number if pagination is active
  const [firstDisplayedRow, setFirstDisplayedRow] = useState(0); // First displayed row
  const [lastDisplayedRow, setLastDisplayedRow] = useState(0); // Last displayed row
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [totalSelectedRowCount, setTotalSelectedRowCount] = useState(0);
  const [rwPlacemarkList, setRWPlacemarkList] = useState([]);
  const [activeRowsIndex, setActiveRowsIndex] = useState(-1);
  const [isRwNavEnabled, setIsRWNavEnabled] = useState(isRWUserOnline);

  const rwNavigateToRow = (rowindex: number) => {
    if (iotConnection !== null && isRwNavEnabled && gridRef.current !== null) {
      const displayedRow = gridRef.current.api.getDisplayedRowAtIndex(rowindex);
      if (displayedRow !== undefined) {
        const rowData = displayedRow.data;
        GridImplementation.processRowForRWFlyTo(
          rowData,
          view,
          rwPlacemarkList,
          setRWPlacemarkList,
          iotConnection
        );

        setActiveRowsIndex(rowindex);
        gridRef.current.api.ensureIndexVisible(rowindex);
        // enforcing re render of rows to ensure cell color change
        gridRef.current.api.redrawRows();
      }
    }
  };

  toolboxRef.current = { totalRowCount, activeRowsIndex, setPageNumber, setTotalPageCount, setFirstDisplayedRow, setLastDisplayedRow, setTotalRowCount, setTotalSelectedRowCount, rwNavigateToRow };

  useEffect(() => {
    setIsRWNavEnabled(isRWUserOnline);
  }, [isRWUserOnline]);

  useEffect(() => {
    if (!isRwNavEnabled) {
      setActiveRowsIndex(-1);
    }
  }, [isRwNavEnabled]);


  /* useEffect hook executes even on initialization of its dependency. 
  To prevent toast warning on intialization (false value by default) of isRWUserOnline variable, this custom hook is being used here */
  useNoInitialRenderEffect(() => {
    if (isRWUserOnline) {
      setToast({ open: true, title: 'RW Connected', text: 'Roames World is ready for navigation', messageType: 'info' });
    } else {
      setToast({ open: true, title: 'RW Disconnected', text: 'Please sign in to Roames World to continue navigation', messageType: 'warning' });
    }
  }, [isRWUserOnline]);

  // This will launch RW in new tab if user has not yet signed in on RW
  const launchRW = () => {
    if (!isRWUserOnline) {
      const newWindow: Window | null = window.open(
        process.env.REACT_APP_ROAMES_WORLD_URL,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  const getFlyToModeButton = () => {
    const determineTooltipTitle = () => {
      if (!isRWUserOnline) {
        return "Activate ROAMES World navigation mode (available after signing in to ROAMES World)";
      } else {
        return isRwNavEnabled
          ? "Click to disable ROAMES World navigation mode"
          : "Click to enable ROAMES World navigation mode";
      }
    };
    return (
      <Tooltip
        title={determineTooltipTitle()}
        key={"tooltip_flyto_toggle"}
        placement="top"
        arrow
        enterDelay={TOOLTIP_TIMING.Enter}
        leaveDelay={TOOLTIP_TIMING.Leave}
      >
        <div className="flytomode-icon-wrapper">
          <div onClick={() => setIsRWNavEnabled(!isRwNavEnabled)}>
            <div
              className={`icon-eye-icon ${!isRWUserOnline ? "disabled" : ""} ${isRwNavEnabled ? "button-enabled" : ""
                }`}
            ></div>
          </div>
        </div>
      </Tooltip>
    );
  };

  const getRWNavigation = () => {
    return isRwNavEnabled ? (
      <RWNavigation
        isRwNavEnabled={isRwNavEnabled}
        totalSelectedRowCount={totalSelectedRowCount}
        rowActiveUp={rowActiveUp}
        rowActiveDown={rowActiveDown}
        rowSelectedUp={rowSelectedUp}
        rowSelectedDown={rowSelectedDown}
      />
    ) :
      <></>
  };

  const onPageFirst = () => {
    if (gridRef.current !== null) {
      gridRef.current.api.paginationGoToFirstPage();
    }
  };

  const onPagePrev = () => {
    if (gridRef.current !== null) {
      gridRef.current.api.paginationGoToPreviousPage();
    }
  }

  const onGoToPage = (pageNumber: number) => {
    if (gridRef.current !== null) {
      gridRef.current.api.paginationGoToPage(pageNumber);
    }
  }

  const onPageNext = () => {
    if (gridRef.current !== null) {
      gridRef.current.api.paginationGoToNextPage();
    }
  }

  const onPageLast = () => {
    if (gridRef.current !== null) {
      gridRef.current.api.paginationGoToLastPage();
    }
  }

  const rowActiveUp = () => {
    if (activeRowsIndex - 1 >= 0) {
      rwNavigateToRow(activeRowsIndex - 1);
    }
  };

  const rowActiveDown = () => {
    if (gridRef.current !== null && activeRowsIndex + 1 < gridRef.current.api.getDisplayedRowCount()) {
      rwNavigateToRow(activeRowsIndex + 1);
    }
  };

  const rowSelectedUp = () => {
    if (gridRef.current !== null && totalSelectedRowCount > 0) {
      const selectedRowsData = gridRef.current.api.getSelectedNodes().reverse();
      let foundClosest = false;

      selectedRowsData.forEach((selectedRow: any) => {
        if ((activeRowsIndex - 1) - selectedRow.rowIndex >= 0 && !foundClosest) {
          foundClosest = true;
          rwNavigateToRow(selectedRow.rowIndex);
        }
      });

      if (!foundClosest && selectedRowsData[0].rowIndex !== null) {
        rwNavigateToRow(selectedRowsData[0].rowIndex);
      }
    }
  };

  const rowSelectedDown = () => {
    if (gridRef.current !== null && totalSelectedRowCount > 0) {

      const selectedRowsData = gridRef.current.api.getSelectedNodes();
      let foundClosest = false;

      selectedRowsData.forEach((selectedRow: any) => {
        if (selectedRow.rowIndex - (activeRowsIndex + 1) >= 0 && !foundClosest) {
          foundClosest = true;
          rwNavigateToRow(selectedRow.rowIndex);
        }
      });

      if (!foundClosest && selectedRowsData[0].rowIndex !== null) {
        rwNavigateToRow(selectedRowsData[0].rowIndex);
      }
    }
  };

  const exportOnClick = () => {
    if (gridRef.current !== null) {
      GridImplementation.exportDatGrid(view, token, gridRef.current, logOut)
    }
  };

  return (
    <div className="toolbox">
      <div className="toolbox__left">
        <div className="toolbox__drawer">
          <Tooltip
            title={pagination ? "Toggle Infinite Scroll" : "Toggle Pagination"}
            key={"tooltip_scroll_pagination_toggle"}
            placement="top"
            arrow
            enterDelay={TOOLTIP_TIMING.Enter}
            leaveDelay={TOOLTIP_TIMING.Leave}
          >
            <div
              className={`toolbox__compartment ${pagination ? "button-enabled" : ""
                }`}
            >
              <ToggleButton
                onToggle={() => setPagination(!pagination)}
                title=""
                icon="chrome_reader_mode"
                initialToggled={false}
              />
              <Button id='fugro-xbox' sx={{display: 'none'}} onClick={exportOnClick}></Button>
            </div>
          </Tooltip>
          <Tooltip
            title={
              isRWUserOnline
                ? "ROAMES World Connected"
                : "Click to launch ROAMES World"
            }
            key={"tooltip_roames_world_toggle"}
            placement="top"
            arrow
            enterDelay={TOOLTIP_TIMING.Enter}
            leaveDelay={TOOLTIP_TIMING.Leave}
          >
            <div
              onClick={() => launchRW()}
              className={`toolbox__compartment ${isRWUserOnline ? "button-enabled" : ""
                }`}
            >
              <div className="button toggle-button icon-roames_world"></div>
            </div>
          </Tooltip>
        </div>
        {getFlyToModeButton()}
        {getRWNavigation()}
      </div>
      <PaginationControls
        pagination={pagination}
        pageNumber={pageNumber}
        totalPageCount={totalPageCount}
        onPageFirst={onPageFirst}
        onPagePrev={onPagePrev}
        onGoToPage={onGoToPage}
        onPageNext={onPageNext}
        onPageLast={onPageLast}
      />
      {(
        <div className="toolbox__right" hidden={isRwNavEnabled}>
          {`Display Item ${firstDisplayedRow.toString()} to ${lastDisplayedRow.toString()} | Total of ${new Intl.NumberFormat().format(totalRowCount)}`}
        </div>
      )}
    </div>
  );
};

export { Toolbox };
