import React, { useEffect, useState } from 'react';

import AppContext from './context/AppContext';
import { Auth } from './auth/Auth';
import { ToastAttributes } from './components/common/Toast';
import { Router } from './components/routing/Router';
import { mqtt } from 'aws-iot-device-sdk-v2';
import { isNullOrUndefined } from './utils/helper';
import { IoTService } from './service/IoTService';

export const App = () => {
  const [idToken, setIdToken] = useState<string | undefined>(undefined);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [profile, setProfile] = useState<any | undefined>(undefined);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);
  const [auth0Imp] = useState(Auth.newAuth0());

  const [isRWUserOnline, setIsRWUserOnline] = useState(false);
  const [iotConnection, setIOTConnection] = useState<mqtt.MqttClientConnection | null>(null);

  const [toast, setToast] = useState<ToastAttributes>({ open: false, text: '', messageType: undefined, title: '' });

  const [isPersistingUrl, setIsPersistingUrl] = useState(false);

  useEffect(() => {
    Auth.onAppStart(auth0Imp, idToken, profile, sessionId, setAccessToken, setIdToken, setProfile, setSessionId);
  }, [auth0Imp, idToken, profile, sessionId, setAccessToken, setIdToken, setProfile, setSessionId]);

  useEffect(() => {
    if (!isNullOrUndefined(idToken) && iotConnection === null) {
      IoTService.init(idToken, setIsRWUserOnline, iotConnection, setIOTConnection);
    }
  }, [idToken, iotConnection]);

  const store = {
    appIdToken: { get: () => idToken, set: setIdToken },
    appAccessToken: { get: () => accessToken, set: setAccessToken },
    appProfile: { get: () => profile, set: setProfile },
    appToast: { get: () => toast, set: setToast },
    appIsPersistingUrl: {get: () => isPersistingUrl, set: setIsPersistingUrl},
    appSessionId: {get: () => sessionId, set: setSessionId},
    appIoTConnection: {get: () => iotConnection, set: setIOTConnection},
    appIsRWUserOnline: {get: () => isRWUserOnline, set: setIsRWUserOnline}
  };

  return <div className="insights-app">
    {
      <AppContext.Provider value={store}>
        <Router token={idToken} profile={profile} sessionId={sessionId} />
      </AppContext.Provider>
    }
  </div>;
};
