import React from "react";
import { User, Profile } from './User';
import { Logo } from './Logo';

interface SidenavProps {
  profile: Profile;
  expanded: boolean;
  toggleExpanded: () => void;
  children: JSX.Element[] | JSX.Element;
}

const Sidenav = ({profile, expanded, toggleExpanded, children}: SidenavProps) => {
  const defaultSidenavClass = 'sidenav';
  const sidenavClass = expanded ? `${defaultSidenavClass} expanded` : defaultSidenavClass;
  return <nav className={sidenavClass}>
    <Logo expanded={expanded} toggleExpanded={toggleExpanded} />
    <User profile={profile} expanded={expanded} />
    <div className='sidenav__content'>
      {children}
    </div>
  </nav>
}

export {
  Sidenav
};
