import React from 'react';
import { ModelView } from '../../model/View';
import { CategorizedViews, CategorizedViewsData } from './CategorizedViews';

export interface ViewsState {
  data: Record<string, ModelView[]> | undefined;
  openCategories: number[];
  uncategorizedViews: ModelView[];
}

interface ViewCategoryProps {
  i: number;
  views: ViewsState;
  setViews: (store: ViewsState) => void;
  data: CategorizedViewsData;
  viewHandle: string | undefined;
  isCategoryOpen: boolean
  resetGridAndSetView: (view: ModelView) => void;
}

const getCategoryOnClick = (i: number, views: ViewsState, setViews: (views: ViewsState) => void) => {
  return () => {
    // If the current selected category is the same as the category that was just clicked
    // Set the category back to initial state (all closed).
    const openCategories = views.openCategories.includes(i) ? views.openCategories.filter((categoryI) => categoryI !== i) : views.openCategories.concat([i]);
    const newViews = {
      ...views,
      openCategories
    };
    setViews(newViews);
  };
};

const ViewCategory = ({i, views, setViews, data, viewHandle, isCategoryOpen, resetGridAndSetView}: ViewCategoryProps) => {
  return <div className={isCategoryOpen ? 'viewcategory viewcategory--open' : 'viewcategory'}>
    <div className='viewcategory__category' onClick={getCategoryOnClick(i, views, setViews)}>
      <div className='viewcategory__icon icon-business' /><div className='viewcategory__name'>{data.key}</div>{isCategoryOpen ? <div className='viewcategory__chevron icon-navigate_next' /> : <></>}
    </div>
    {isCategoryOpen ? 
      <CategorizedViews viewHandle={viewHandle} data={data} resetGridAndSetView={resetGridAndSetView} /> : <></> // Don't render anything for non-selected categories instead of just hiding the divs through CSS
    }
  </div>
}

export {
  ViewCategory
};
