import { ServiceBase } from './ServiceBase';

const getViewDefinitions = (token: string, logOut: () => void): Promise<any> => {
  const viewApi = process.env.REACT_APP_VIEW_API_URL;
  const url = `${viewApi}/view?application_support=false`;
  return ServiceBase.authGet(url, token, '*/*', logOut);
};

const getViewDefinition = (token: string, handle: string, logOut: () => void): Promise<any> => {
  const viewApi = process.env.REACT_APP_VIEW_API_URL;
  const url = `${viewApi}/view/${handle}`;
  return ServiceBase.authGet(url, token, '*/*', logOut);
};

export const ViewService = {
  getViewDefinitions,
  getViewDefinition
}