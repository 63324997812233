import { ModelView } from '../model/View';
import { ServiceBase } from './ServiceBase';

const getViewData = (token: string, handle: string, limit: number, sortString: string,
  filterString: string, startRow: number, contentType: string, logOut: () => void, hiddenCols: string[], includeHeaders?: boolean, includeFooters?: boolean): Promise<any> => {

  const viewApi = process.env.REACT_APP_VIEW_API_URL;

  const queryParams: URLSearchParams = new URLSearchParams({
    limit: `${limit}`,
    offset: `${startRow}`,
    hidden_cols: JSON.stringify(hiddenCols),
  });

  if (filterString.length > 0) queryParams.append('filter', filterString);
  if (sortString.length > 0) queryParams.append('sort', `[${sortString}]`);
  if (includeHeaders !== undefined) queryParams.append('include_headers', `${includeHeaders}`);
  if (includeFooters !== undefined) queryParams.append('include_footers', `${includeFooters}`);

  const url = `${viewApi}/view/${handle}/data?${queryParams}`;

  return ServiceBase.authGet(url, token, contentType, logOut);
};

const getDistinctValues = (token: string, handle: string, limit: number, column: string, contentType: string, logOut: () => void) => {
  const queryParams = new URLSearchParams({
    distinct:  JSON.stringify([column]),
    no_count: 'true',
    limit: `${limit}`,
  });

  const viewApi = process.env.REACT_APP_VIEW_API_URL;

  const url = `${viewApi}/view/${handle}/data?${queryParams}`;
  
  return ServiceBase.authGet(url, token, contentType, logOut);
};

const createView = (token: string, view: ModelView) => {
  const viewApi = process.env.REACT_APP_VIEW_API_URL;
  const url = `${viewApi}/view`;
  const contentType = 'application/json';
  const content = JSON.stringify(view);
  return ServiceBase.authPost(url, token, contentType, content);
};

// Since we destroyed a couple of the variables, the code no longer recognizes it as a view thus we have to use any.
const cleanAssetType = (view: any) => {
  const {
    asset_type: destroyed,
    ...cleanedView
  } = view;
  return cleanedView;
};

const updateView = (token: string, view: ModelView) => {
  const viewApi = process.env.REACT_APP_VIEW_API_URL;
  const handle = view.view_handle;
  const url = `${viewApi}/view/${handle}`;
  const {
    view_handle: destroyed1,
    created_at: destroyed2,
    updated_at: destroyed3,
    created_by: destroyed4,
    updated_by: destroyed5,
    ...cleanedView
  } = view;

  const newView = !cleanedView.enable_custom_attributes ? cleanAssetType(cleanedView) : cleanedView;

  const contentType = 'application/json';
  const contentObj = { view: newView };
  const content = JSON.stringify(contentObj);
  return ServiceBase.authPut(url, token, contentType, content);
};

const putViewData = (token: string, handle: string, fieldValues: Record<string, string>, ids: Record<string, string | number>[]): Promise<any> => {
  const viewApi = process.env.REACT_APP_VIEW_API_URL;
  const url = `${viewApi}/view/${handle}/data2`;
  const body = {
    fieldValues,
    ids
  };
  const contentType = 'application/json';
  const content = JSON.stringify(body);

  return ServiceBase.authPut(url, token, contentType, content);
};

export const ViewDataService = {
  getViewData,
  getDistinctValues,
  createView,
  updateView,
  putViewData
}