import { ServiceBase } from './ServiceBase';

const getViewCustomisation = (token: string, userId: string, handle: string, logOut: () => void): Promise<any> => {
  const customerApi = process.env.REACT_APP_CUSTOMER_API_URL;
  const filter = `owner eq "${userId}" and ownerType eq USER and targetType eq AnalyticsView and target eq "${handle}"`
  const url = `${customerApi}/Customisation?@filter=${filter}`;
  return ServiceBase.authGet(url, token, '*/*', logOut);
};

export const ViewCustomisationService = {
  getViewCustomisation
}