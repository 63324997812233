import React from "react";
import { ModelView } from "../../model/View";
import Button from "@mui/material/Button";

export interface PastNavigation {
  previousState: {
    view: ModelView;
    filterModel: any;
    sortModel: any;
    columnState: any;
  };
  view: ModelView;
  preFilter: any;
}
interface PastNavigationsProps {
  navigations: PastNavigation[];
  navigateBackToView: (index: number) => void;
}

const PastNavigations = ({
  navigations,
  navigateBackToView,
}: PastNavigationsProps) => {
  const navs = navigations.map((navigation, i) => {
    const onClick = () => {
      navigateBackToView(i);
    };

    return (
      <Button
        className="navigation-button"
        key={i}
        onClick={onClick}
        color="primary"
      >
        <div className="navigation-textBox">
          <div className="navigation-text">
            <div className="navigation-text-ellipsis">
              {navigation.previousState.view.display_name}
            </div>
          </div>
          <div className="navigation-icon icon-arrow_up" />
        </div>
      </Button>
    );
  });
  return <div className="pastnavigations">{navs}</div>;
};

export { PastNavigations };
