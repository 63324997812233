import React, { Dispatch, MutableRefObject, RefObject, SetStateAction, useEffect, useState } from "react";
import { Cache, FilterModel, GridFiltering } from './GridFiltering';
import { ToastAttributes } from "../common/Toast";
import { PageTopFilter } from './PageTopFilter';
import { CurrentCycleSelector } from './CurrentCycleSelector';
import { ModelView } from "../../model/View";
import { isNullOrUndefined } from "../../utils/helper";
import { GridImplementation } from "./GridImplementation";
import { FilterModelRepresentation, getOnDelete } from "./FilterModelRepresentation";
import { AgGridReact } from "ag-grid-react";

export interface IPageTopFiltersRef {
  cache: Cache;
  setCache: Dispatch<SetStateAction<Cache>>;
  setFilterModel: Dispatch<SetStateAction<FilterModel>>;
};

interface PageTopFiltersProps {
  profile: any;
  token: string;
  sessionId: string;
  logOut: () => void;
  view: ModelView;
  gridRef: RefObject<AgGridReact<any>>;
  pageTopFiltersRef: MutableRefObject<IPageTopFiltersRef | null>;
  setToast: (args: ToastAttributes) => void;
}

const getDefaultCache = (token: string, view: any, logOut: () => void): Cache => {
  return GridFiltering.buildAttributeFilterCache(token, view, logOut);
};

const PageTopFilters = ({ profile, token, sessionId, logOut, view, gridRef, pageTopFiltersRef, setToast }: PageTopFiltersProps) => {
  const updateFilterModel = GridImplementation.getUpdateFilterModel(view.view_handle, token, sessionId, profile, gridRef);

  const [filterModel, setFilterModel] = useState<FilterModel | null>(null);
  const [cache, setCache] = useState<Cache>(getDefaultCache(token, view, logOut));

  pageTopFiltersRef.current = {cache, setCache, setFilterModel};

  useEffect(() => {
    let active = true;

    const keys = Object.keys(cache);

    // Only find one of these at a time
    const loadingKey = keys.find((key) => {
      const cacheEntry = cache[key];
      return cacheEntry.open && cacheEntry.values === undefined;
    });

    if (loadingKey && active) {
      cache[loadingKey].fetchValues(active, cache, setCache, setToast);
    }

    return () => {
      active = false;
    }
  }, [filterModel, cache, setCache, setToast]);

  const keys = Object.keys(cache);

  const listFilters = keys.map((cacheKey, i) => {
    const cacheEntry = cache[cacheKey];
    const filterEntries = filterModel !== null ? filterModel[cacheKey] : undefined;
    const cacheValues = cacheEntry.values;

    const label = cacheEntry.displayName;
    const open = cacheEntry.open;
    const loading = open && cacheEntry.values === undefined;
    const values = cacheValues !== undefined && open ? cacheValues.map((option: string) => {
      return {
        value: isNullOrUndefined(option) ? null : option.toString(),
        name: isNullOrUndefined(option) ? "blank" : option.toString()
      };
    }) : [];
    const selected = filterEntries?.values?.length > 0 ? filterEntries.values.map((option: any) => {
      return {
        value: isNullOrUndefined(option) ? null : option.toString(),
        name: isNullOrUndefined(option) ? "blank" : option.toString()
      }
    }) : [];        
    const onOpen = () => {
      if (cacheEntry !== undefined) {
        cacheEntry.setOpen(true, cache, setCache);
      }
    };
    const onClose = () => {
      if (cacheEntry !== undefined) {
        cacheEntry.setOpen(false, cache, setCache);
      }
    };
    const onChange = (event: any, values: any[]) => {
      if (values.length > 0) {
        const stringValues = values.map((value: any) => value.value);
        updateFilterModel(cacheKey, stringValues);
      } else {
        updateFilterModel(cacheKey, undefined);
      }
    }
    return <PageTopFilter
      key={i}
      label={label}
      open={open}
      loading={loading}
      values={values}
      selected={selected}
      onOpen={onOpen}
      onClose={onClose}
      onChange={onChange} />
  });  

  return <div className={'filtergrid'}>
    <div className={'filterselects'}>
    {
      GridFiltering.attributesContainsCycleSelector(view.attributes) ? 
        <CurrentCycleSelector filterModel={filterModel} updateFilterModel={updateFilterModel} /> :
        <></>
    }
    { listFilters }
    </div>
    <FilterModelRepresentation view={view} getOnDelete={getOnDelete(token, profile, sessionId, view.view_handle, gridRef)} filterModel={filterModel} />
  </div>;
};

export {
  PageTopFilters
};
