import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Landing } from './Landing';
import { ProtectedRoute } from './ProtectedRoute';
import { Viewer } from './viewType/Viewer';
import { Auth } from '../../auth/Auth';
import AppContext from '../../context/AppContext';

interface RouterProps {
  token: string | undefined;
  profile: any;
  sessionId: any;
}

const Router = ({ token, profile, sessionId }: RouterProps) => {
  const { appIdToken, appAccessToken, appProfile, appIsPersistingUrl } = useContext(AppContext);
  const logOut = Auth.getLogout(appAccessToken.set, appIdToken.set, appProfile.set, appIsPersistingUrl.set, false);
  return <BrowserRouter>
    <Switch>
      <Route exact={true} path="/">
        <Landing />
      </Route>
      <ProtectedRoute exact={false} path="/:viewType">
        <Viewer signOut={logOut} token={token} sessionId={sessionId} profile={profile} ></Viewer>
      </ProtectedRoute>
    </Switch>
  </BrowserRouter>;
}

export {
  Router
}