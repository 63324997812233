import { ViewDataService } from '../../service/ViewDataService';
import View, { ModelView } from '../../model/View';
import {
  CellRange,
  ColumnApi,
  CsvExportParams,
  ExcelExportParams,
  GetContextMenuItemsParams,
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  MenuItemDef
} from 'ag-grid-enterprise';
import {
  BodyScrollEvent,
  ColumnState,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridReadyEvent,
  PaginationChangedEvent,
  RowClickedEvent,
  SelectionChangedEvent,
  SortModelItem
} from 'ag-grid-community';
import { Cache, FilterModel, GridFiltering } from './GridFiltering';
import { MAX_NUMBER_OF_FILTER_ENTRIES, MAX_ROWS_EXPORT, MEDIA_TYPE, SHAPE_TYPE } from "../../constants";
import downloadFile from "../../utils/downloadFile";
import { ToastAttributes } from "../common/Toast";
import { IError } from "../../service/ServiceBase";
import { RWService } from "../../service/RWService";
import { mqtt } from 'aws-iot-device-sdk-v2';
import { calculateProgressInPercentage, getDateStringAsPerLocale, getDateTimeStringAsPerLocale, isNullOrUndefined } from "../../utils/helper";
import { PastNavigation } from './PastNavigations';
import { AgGridReact } from 'ag-grid-react';
import { RefObject } from 'react';
import { FileNameDialogOutputParams } from './dialogs/file-name-dialog/FileNameDialogInterfaces';
import { Events, logEvent } from '../../service/LoggingService';
import { numeric_types } from './GridAttributes';
import { GridFormatters } from './GridFormatters';

const blockSize = 1000;
const csvBlockSize = 100000;
const navigateFilterLimit = 100;

const getSortModelFromColumnState = (columnState: ColumnState[]): SortModelItem[] => {
  const hasSortColStates = columnState.filter((s) => {
    return s.sort !== null && s.sort !== undefined;
  });

  const indicized = hasSortColStates.map((s) => {
    return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
  });

  const sorted = indicized.sort((a, b) => {
    if ((a.sortIndex === undefined || a.sortIndex === null) && (b.sortIndex === undefined || b.sortIndex === null)) {
      return 0;
    } else if ((a.sortIndex === undefined || a.sortIndex === null)) {
      return 1;
    } else if ((b.sortIndex === undefined || b.sortIndex === null)) {
      return -1;
    } else {
      return a.sortIndex - b.sortIndex;
    }
  });

  return sorted.map((s) => {
    return { colId: s.colId, sort: s.sort }
  }) as SortModelItem[];
};

const getUpdateFilterModel = (view_handle: string, token: string, sessionId: string, profile: any, gridRef: RefObject<AgGridReact<any>>) => {
  return (attrName: string, selected: string[] | undefined) => {
    if (gridRef.current !== null) {
      if (selected !== undefined) {
        const filterModel = gridRef.current.api.getFilterModel();
        const newFilterModel = {
          ...filterModel,
          [attrName]: {
            type: 'set',
            values: selected
          }
        };
        logEvent(token, profile, Events.FilterGridFromPageTopFilters, {
          session_id: sessionId,
          view_handle,
          attrName,
          values: selected
        });
        gridRef.current.api.setFilterModel(newFilterModel);
      } else {
        const filterModel = gridRef.current.api.getFilterModel();
        // Remove attrName property from filter object
        // The spread syntax here can be a little confusing,
        // but what this is saying is that the property with attribute name is being assigned to the variable "destroyed"
        // while the rest of the object is assigned to "rest", in this way, you can have a new object created without
        // the attrName property without mutating anything through the use of the delete keyword
        const { [attrName]: destroyed, ...rest } = filterModel;
        logEvent(token, profile, Events.RemoveFilterFromGridFromPageTopFilters, {
          session_id: sessionId,
          view_handle,
          attrName,
          values: []
        });
        gridRef.current.api.setFilterModel(rest);
      }
    }
  };
};

const constructSortStringFromSortModel = (sortModel: SortModelItem[]) => {
  const modelStrings = sortModel.map((modelEntry: SortModelItem) => {
    return `"${modelEntry.colId} ${modelEntry.sort}"`;
  });
  return modelStrings.join(',');
};

const getDataSource = (view: ModelView, token: string, getSetTotalRowCount: () => (count: number) => void, setToast: (args: ToastAttributes) => void, preFilter: any, logOut: () => void): IServerSideDatasource => {
  const getRows = (params: IServerSideGetRowsParams) => {
    const setTotalRowCount = getSetTotalRowCount();
    const sortModel = params.request.sortModel;
    const sortString = constructSortStringFromSortModel(sortModel);
    const filterModel = params.api.getFilterModel();
    const filterString = GridFiltering.constructFilterStringFromFilterModelWithPrefilter(preFilter, filterModel);
    const startRow = params.request.startRow !== undefined ? params.request.startRow : 0;
    ViewDataService.getViewData(token, view.view_handle, blockSize, sortString, filterString, startRow, '*/*', logOut, [])
      .then((response) => {
        const totalRows = parseInt(response.rowcount, 10);
        setTotalRowCount(totalRows);
        params.success({
          rowData: response.rows,
          rowCount: totalRows
        });
      })
      .catch((e: IError) => {
        setToast({ open: true, text: e.message, messageType: 'error', title: e.error ? e.error : 'Error in fetching data' });
        params.fail();
      });
  };

  return {
    getRows
  };
};

const arrayToCsv = (data: any[]) => {
  return data.map(row =>
    row.map(String)  // convert every value to String
      .map((v: String) => v.replaceAll('"', '""'))  // escape double quotes
      .map((v: String) => `"${v}"`)  // quote it
      .join(',')  // comma-separated
  ).join('\r\n');  // rows starting on new lines
};

const downloadBlob = (content: string, filename: string, contentType: string) => {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
  pom.remove();
};

const getUnformattedValue = (value: string | null | undefined) => value;

const getValueFormatter = (attribute: any) => {
  if (numeric_types.indexOf(attribute.primitive_type) > -1) {
    const cellFormatter = GridFormatters.cell_formatters.find(pred => pred.name === attribute.formatter_name);
    return cellFormatter ? (value: string | null | undefined) => cellFormatter.formatter_function(value)
      : getUnformattedValue;
  } else if (attribute.primitive_type === 'Date') {
    return (value: string | null | undefined) => value ? getDateStringAsPerLocale(value) : value;
  } else if (attribute.primitive_type === 'Date/Time') {
    return (value: string | null | undefined) => value ? getDateTimeStringAsPerLocale(value) : value;
  };
  return getUnformattedValue;
};

const exportGridYo = (batchStartRow: number, startRow: number, endRow: number | undefined, currentContent: (string | null | undefined)[][], sortString: string, filterString: string, visibleCols: ColumnState[], view: ModelView, token: string, logOut: () => void) => {
  const nextStart = startRow + blockSize;
  const fullNextStart = batchStartRow + nextStart;
  const nextBatchStart = batchStartRow + csvBlockSize;
  const headerNames: (string | null | undefined)[] = [];

  ViewDataService.getViewData(token, view.view_handle, blockSize, sortString, filterString, batchStartRow + startRow, '*/*', logOut, [])
    .then((response: any) => {
      const totalRows = parseInt(response.rowcount, 10);

      // Append rows for each column
      visibleCols.forEach((col) => {        
        // TODO: ask hamilton, do we want to add column headers or not?
        const matchedAttribute = view.attributes.find((attribute: any) => {
          return attribute.name === col.colId;
        });
        // Only append attributes that are found
        if (matchedAttribute) {
          // Add the header name to the array of header names
          headerNames.push(col.colId);
          const valueFormatter = getValueFormatter(matchedAttribute);
          for (let rowIndex = 0; rowIndex < response.rows.length; rowIndex++) {
            const attemptedValue = response.rows[rowIndex][col.colId];
            const newValue = valueFormatter(attemptedValue);
            const newContent = [`${newValue}`];
            const currentIndex = startRow + rowIndex;
            const newRow = currentContent[currentIndex] === undefined ? newContent : currentContent[currentIndex].concat(newContent)
            currentContent[currentIndex] = newRow;
          }
        }
      });
      
      // If the current batch is finished, or the next start in the current batch is more than the total rows
      if (fullNextStart === nextBatchStart || fullNextStart > totalRows || endRow !== undefined && fullNextStart > endRow) {
        // Add the header names of the cols
        const csvRows = [headerNames].concat(currentContent);
        const csvContent = arrayToCsv(csvRows);

        downloadBlob(csvContent, 'export.csv', 'text/csv;charset=utf-8;');
        
        // If the next start in the current batch hasn't been reached, we've reached the end of a batch so start again
        if (fullNextStart < totalRows && endRow !== undefined && fullNextStart < endRow) {
          exportGridYo(nextBatchStart, 0, endRow, [], sortString, filterString, visibleCols, view, token, logOut);
        }
      } else {
        exportGridYo(batchStartRow, nextStart, endRow, currentContent, sortString, filterString, visibleCols, view, token, logOut);
      }
    });
};

// We need to redefine window with fugroParams as a property if we don't want it to complain
declare global {
  interface Window { fugroParams: any; }
}

const exportDatGrid = (view: ModelView, token: string, grid: AgGridReact<any>, logOut: () => void) => {
  const columnState = grid.columnApi.getColumnState();
  const sortModel = getSortModelFromColumnState(columnState);
  const sortString = constructSortStringFromSortModel(sortModel);
  const filterModel = grid.api.getFilterModel();
  const filterString = GridFiltering.constructFilterStringFromFilterModel(filterModel);
  const visibleCols = columnState.filter((col) => {
    return col.hide !== true;
  });
  const fugroParams = window.fugroParams;
  const {paramStartRow, paramEndRow} = fugroParams;
  const startRow = paramStartRow ? paramStartRow : 0;
  const startBatchRow = startRow > 0 ? Math.floor(startRow % csvBlockSize) : 0;
  exportGridYo(startBatchRow, startRow, paramEndRow, [], sortString, filterString, visibleCols, view, token, logOut);
};

const getOnGridReady = (sortModel: SortModelItem[], filterModel: FilterModel, columnState: any) => {
  return (params: GridReadyEvent) => {
    params.api.setFilterModel(filterModel);
    if (columnState.length > 0) {
      params.columnApi.applyColumnState({
        state: columnState
      });
    }
    params.columnApi.applyColumnState({
      state: sortModel,
      defaultState: { sort: null }
    });
  };
};

const getOnPaginationChanged = (getSetPageNumber: () => (count: number) => void, getSetTotalPageCount: () => (count: number) => void, getSetFirstDisplayedRow: () => (row: number) => void, getSetLastDisplayedRow: () => (row: number) => void) => {
  return (event: PaginationChangedEvent) => {
    const setPageNumber = getSetPageNumber();
    const setTotalPageCount = getSetTotalPageCount();
    const setFirstDisplayedRow = getSetFirstDisplayedRow();
    const setLastDisplayedRow = getSetLastDisplayedRow();

    setPageNumber(event.api.paginationGetCurrentPage());
    setTotalPageCount(event.api.paginationGetTotalPages());
    const firstDisplayedRowIndex = determineRowIndex(event.api.getFirstDisplayedRow(), event.api.getDisplayedRowCount());
    const lastDisplayedRowIndex = determineRowIndex(event.api.getLastDisplayedRow(), event.api.getDisplayedRowCount());
    // We need this here, as the "onPaginationChanged" event fires when the data has been loaded and then displayed on the page, even in infinite scroll mode
    setFirstDisplayedRow(firstDisplayedRowIndex);
    setLastDisplayedRow(lastDisplayedRowIndex);
  }
};

/** ag-grid's getFirstDisplayedRow() and getLastDisplayedRow() starts index from zero. To give user idea of true row count we need to add 1 to 
  each index except when there are no rows */
const determineRowIndex = (agGridRowIndex: number, totalRowCount: number): number => {
  if (totalRowCount === 0) {
    return 0;
  } else {
    return agGridRowIndex + 1;
  }
}

const getOnBodyScroll = (getSetFirstDisplayedRow: () => (row: number) => void, getSetLastDisplayedRow: () => (row: number) => void) => {
  return (event: BodyScrollEvent) => {
    const setFirstDisplayedRow = getSetFirstDisplayedRow();
    const setLastDisplayedRow = getSetLastDisplayedRow();

    const firstDisplayedRowIndex = determineRowIndex(event.api.getFirstDisplayedRow(), event.api.getDisplayedRowCount());
    const lastDisplayedRowIndex = determineRowIndex(event.api.getLastDisplayedRow(), event.api.getDisplayedRowCount());

    setFirstDisplayedRow(firstDisplayedRowIndex);
    setLastDisplayedRow(lastDisplayedRowIndex);
  };
};

const skipHeader = false;

const getOnFirstDataRendered = () => {
  return (event: FirstDataRenderedEvent) => {
    event.columnApi.autoSizeAllColumns(skipHeader);
  };
};

const getOnFilterChanged = (token: string, profile: any, sessionId: string, view_handle: string, getSetFilterModel: () => (filterModel: FilterModel) => void, setToast: (args: ToastAttributes) => void) => {
  return (event: FilterChangedEvent) => {
    const setFilterModel = getSetFilterModel();
    const filterModel = event.api.getFilterModel();
    const filterColumns: string[] = Object.keys(filterModel);
    for (const col of filterColumns) {
      if (filterModel[col]?.values?.length > MAX_NUMBER_OF_FILTER_ENTRIES) {
        const setFilter = event.api.getFilterInstance(col);
        if (setFilter !== null && setFilter !== undefined) {
          setFilter.setModel(null);
        }
        setToast({ open: true, messageType: 'error', title: 'Too many items in the filter', text: `Please select not more than ${MAX_NUMBER_OF_FILTER_ENTRIES} items` })
        return;
      }
    }
    logEvent(token, profile, Events.FilterChange, {
      session_id: sessionId,
      view_handle,
      filterModel
    });
    setFilterModel(filterModel);
  };
};

const getOnRowClicked = (getNavigateToRow: () => (index: number) => void) => {
  return (params: RowClickedEvent) => {
    if (params.rowIndex !== null) {
      const navigateToRow = getNavigateToRow();
      navigateToRow(params.rowIndex);
    }
  };
};

const constructSortStringFromColumnState = (columnState: ColumnState[]) => {
  const sortModel = getSortModelFromColumnState(columnState);
  const modelStrings = sortModel.map((modelEntry: SortModelItem) => {
    return `"${modelEntry.colId} ${modelEntry.sort}"`;
  });
  return modelStrings.join(',');
};

const processAllRowsExport = (token: string, view: ModelView, navigations: PastNavigation[], fileType: string, params: GetContextMenuItemsParams, exportSize: number,
  logOut: () => void,
  setToast: (args: ToastAttributes) => void, totalRowCount: number, setProgress: (arg: number) => void,
  withHiddenColumns: boolean, fileName: string) => {
  const columnState = params.columnApi.getColumnState();
  const sortString = constructSortStringFromColumnState(columnState);

  const preFilter = navigations.length > 0 ? navigations[navigations.length - 1].preFilter : [];
  const filterModel = params.api.getFilterModel();
  const filterString = GridFiltering.constructFilterStringFromFilterModelWithPrefilter(preFilter, filterModel);

  const hidden_cols = !withHiddenColumns && !isNullOrUndefined(params.columnApi) ? getHiddenColumnList(params.columnApi) : [];

  fetchPaginatedData(token, view, sortString, filterString, fileType, logOut, hidden_cols, setProgress, totalRowCount, exportSize, setToast, fileName);
};

const fetchPaginatedData = async (token: string, view: ModelView, sortString: string, filterString: string, fileType: string,
  logOut: () => void, hidden_cols: string[], setProgress: (arg: number) => void, totalRowCount: number, exportSize: number,
  setToast: (args: ToastAttributes) => void, fileName: string) => {

  const rowsToBeFetchedInEachRequest: number = 8192;
  const rowsToBeFetchedInTotal: number = totalRowCount < exportSize ? totalRowCount : exportSize;

  const numberOfApiCalls: number = Math.ceil(rowsToBeFetchedInTotal / rowsToBeFetchedInEachRequest);

  setProgress(0);

  let offset: number = 0;
  let accumulator: any[] = []; // this accumulator will hold data till entire dataset is downloaded

  try {
    for (let k = 0; k < numberOfApiCalls; k++) {
      const percentageCompleted = calculateProgressInPercentage(k, numberOfApiCalls);
      setProgress(percentageCompleted);
      const include_headers = k === 0;
      const include_footers = k === numberOfApiCalls - 1;
      const response = await ViewDataService.getViewData(token, view.view_handle, rowsToBeFetchedInEachRequest, sortString, filterString, offset, MEDIA_TYPE[fileType], logOut, hidden_cols, include_headers, include_footers);
      accumulator = appendNewDataToDataAccumulator(accumulator, response);
      offset = offset + rowsToBeFetchedInEachRequest;
    }

    downloadFile(accumulator.join('\n'), fileName, fileType);
  } catch (error) {
    console.error(error);
    const err = error as any;
    setToast({
      open: true,
      messageType: 'error',
      text: err?.message,
      title: 'Export failed'
    });
  }

  setProgress(-1);
}

const appendNewDataToDataAccumulator = (dataAccumulator: any[], newData: object) => {
  dataAccumulator.push(newData);
  return dataAccumulator;
}

const openFileNameDialog = (fileTypeOptions: string[], totalRowCount: number, cb: (arg: FileNameDialogOutputParams) => void, setFileDialogAttrs: (attrs: any) => void) => {
  setFileDialogAttrs({
    fileTypeOptions: fileTypeOptions, totalRowCount: totalRowCount, open: true, submitCallback: (dialogParams: FileNameDialogOutputParams) => {
      cb(dialogParams);
    }
  });
}

const processSelectedRowsExport = (token: string, view: ModelView, exportSize: number, fileType: string, params: GetContextMenuItemsParams,
  setToast: (args: ToastAttributes) => void, logOut: () => void, selectedRowCount: number, fileName: string) => {

  const gridApi = params.api;

  switch (fileType) {
    case "KML": {
      gridApi.showLoadingOverlay();
      const selfKeys = view.attributes.filter((item: any) => item.self_key) as any[];
      if (selfKeys.length < 1) {
        gridApi.hideOverlay();
        setToast({ open: true, messageType: 'error', text: 'Self key not found for this grid', title: 'Unable to export KML' });
        break;
      } else {
        const filterForKMLExport = GridFiltering.generateFilterForSelectedKMLExport(gridApi, selfKeys);
        const columnState = params.columnApi.getColumnState();
        const sortString = constructSortStringFromColumnState(columnState);
        const hidden_cols = getHiddenColumnList(params.columnApi);

        ViewDataService.getViewData(token, view.view_handle, exportSize, sortString, filterForKMLExport, 0, MEDIA_TYPE['KML'], logOut, hidden_cols)
          .then((response) => {
            gridApi.hideOverlay();
            downloadFile(response, fileName, fileType);
          })
          .catch((e: IError) => {
            setToast({ open: true, text: e.message, messageType: 'error', title: e.error ? e.error : 'Error in fetching data' });
          });
        break;
      }
    }
    case "XLSX": {
      const exportParams: ExcelExportParams = {
        onlySelected: true,
        fileName: `${fileName}.${fileType.toLowerCase()}`
      }
      gridApi.exportDataAsExcel(exportParams);
      break;
    }
    case "CSV": { //CSV
      const exportParams: CsvExportParams = {
        onlySelected: true,
        fileName: `${fileName}.${fileType.toLowerCase()}`
      }
      gridApi.exportDataAsCsv(exportParams);
      break;
    }
    default: { //InvalidExtract
      setToast({
        open: true,
        messageType: 'error',
        text: 'The extract type that was attempted to be used is not currently configured',
        title: 'Invalid Extract'
      });
    }
  }
}

const selectHighlightedRows = (gridOptions: any) => {
  const highlightedRows: CellRange[] = gridOptions.api.getCellRanges();

  highlightedRows.forEach((range: CellRange) => {
    const rangeStart = range?.startRow?.rowIndex as number,
      rangeEnd = range?.endRow?.rowIndex as number

    const reversed = rangeEnd < rangeStart;
    const loopInitializer: number = reversed ? rangeEnd : rangeStart,
      loopEnd: number = reversed ? rangeStart : rangeEnd;


    const isValidRange = !isNullOrUndefined(rangeStart) && !isNullOrUndefined(rangeEnd);

    if (isValidRange) {
      for (let i = loopInitializer; i <= loopEnd; i++) {
        gridOptions.api.getDisplayedRowAtIndex(i).setSelected(true);
      }
    }

  });

  gridOptions.api.clearRangeSelection();
};

const deselectHighlightedRows = (gridApi: GridApi) => {
  gridApi.deselectAll();
};

const navigateWith = (gridApi: any, columnApi: any, view: ModelView, views: any, navigation: any, navigate: (newView: ModelView, previousState: any) => void, setToast: (args: ToastAttributes) => void) => {
  const filterModel = gridApi.getFilterModel();
  const sortModel = gridApi.getSortModel();
  const columnState = columnApi?.getColumnState();
  const previousState = {
    view,
    filterModel,
    sortModel,
    columnState
  };
  const newView = views.uncategorizedViews.find((view: any) => {
    return view.view_handle === navigation.target_view;
  });
  if (newView !== undefined) {
    return navigate(newView, previousState);
  } else {
    setToast({ open: true, text: 'The selected view could not be found.  Please refresh the page and try again.', messageType: 'error', title: 'Error navigating to selected view' });
  }
};

const getMenuItems = (token: string, view: ModelView, getSetFileDialogAttrs: () => (attrs: any) => void,
  navigations: any[], setNavigations: (navigation: any[]) => void, setToast: (args: ToastAttributes) => void, views: any, getCache: () => Cache | undefined,
  logOut: () => void, params: GetContextMenuItemsParams, totalRowCount: number, getSetProgress: () => (arg: number) => void) => {
  const cache = getCache();
  const gridApi = params.api;
  if (gridApi === null || gridApi === undefined) {
    console.error('Grid api is not present');
    return [];
  }

  const setFileDialogAttrs = getSetFileDialogAttrs();
  const setProgress = getSetProgress();

  const selectedRowCount: number = gridApi.getSelectedRows().length;
  const selectedRowExportOption: MenuItemDef = {
    name: 'Export selected rows',
    action: () => openFileNameDialog(['CSV', 'XLSX', 'KML'], totalRowCount, (arg: FileNameDialogOutputParams) => {
      if (arg.shouldFileDownload) {
        processSelectedRowsExport(token, view, MAX_ROWS_EXPORT[arg.fileType as keyof typeof MAX_ROWS_EXPORT], arg.fileType, params, setToast, logOut, selectedRowCount, arg.fileName)
      }
    }, setFileDialogAttrs),
    disabled: params.api?.getSelectedRows().length === 0
  };

  const allRowsExportOption: MenuItemDef = {
    name: 'Export all rows...',
    action: () => openFileNameDialog(['CSV', 'KML'], totalRowCount, (arg: FileNameDialogOutputParams) => {
      if (arg.shouldFileDownload) {
        processAllRowsExport(token, view, navigations, arg.fileType, params, MAX_ROWS_EXPORT[arg.fileType as keyof typeof MAX_ROWS_EXPORT], logOut, setToast, totalRowCount, setProgress, false, arg.fileName)
      }
    }, setFileDialogAttrs)
  };

  const allRowsWithHiddenExportOption: MenuItemDef = {
    name: 'Export all rows with hidden columns...',
    action: () => openFileNameDialog(['CSV', 'KML'], totalRowCount, (arg: FileNameDialogOutputParams) => {
      if (arg.shouldFileDownload) {
        processAllRowsExport(token, view, navigations, arg.fileType, params, MAX_ROWS_EXPORT[arg.fileType as keyof typeof MAX_ROWS_EXPORT], logOut, setToast, totalRowCount, setProgress, true, arg.fileName)
      }
    }, setFileDialogAttrs)
  };

  const rowsInRange = params.api?.getCellRanges() as [];
  const selectHighlightedRowsOption: MenuItemDef = {
    name: 'Select highlighted rows',
    action: () => selectHighlightedRows(params),
    disabled: !isNullOrUndefined(rowsInRange) && rowsInRange.length < 1
  };

  const deselectHighlightedRowsOption: MenuItemDef = {
    name: 'Deselect highlighted rows',
    action: () => deselectHighlightedRows(gridApi),
    disabled: params.api?.getSelectedRows().length === 0
  };

  const navigateWithSelectedRowsSubmenuItems = view.navigations !== undefined ? view.navigations.map((navigation: any) => {
    return {
      name: navigation.title,
      action: () => {
        const navigate = (newView: ModelView, previousState: any) => {
          const selectedRows = gridApi.getSelectedRows();

          const preFilter = navigation.mappers.reduce((acc: any, mapper: any) => {
            const mapped = selectedRows.map((selectedRow: any) => {
              return selectedRow[mapper.source_attribute];
            });
            const unique = mapped.filter((value: any, index: any, self: any) => self.indexOf(value) === index);
            return {
              ...acc,
              [mapper.target_attribute]: unique
            };
          }, {});

          const newNavigations = navigations.concat([{
            previousState,
            view: newView,
            preFilter
          }]);
          setNavigations(newNavigations);
        };

        navigateWith(gridApi, params.columnApi, view, views, navigation, navigate, setToast);
      }
    }
  }) : [];
  const navigateWithSelectedRows: MenuItemDef = {
    name: 'Navigate with selected rows',
    subMenu: navigateWithSelectedRowsSubmenuItems,
    disabled: (view.navigations === undefined || view.navigations.length < 1 || params.api.getSelectedRows().length === 0)
  };

  const navigateWithFilteredRowsSubmenuItems = view.navigations !== undefined ? view.navigations.map((navigation: any) => {
    return {
      name: navigation.title,
      action: () => {
        const navigate = (newView: ModelView, previousState: any) => {
          const hasSetFilterMappers = navigation.mappers.filter((mapper: any) => {
            return cache !== undefined && cache.hasOwnProperty(mapper.source_attribute);
          });

          const filterValuesPromises = hasSetFilterMappers.map((mapper: any) => {
            return cache !== undefined && cache[mapper.source_attribute].getValuesPromise(previousState.filterModel, cache, setToast);
          });

          Promise.all(filterValuesPromises).then((values: any[]) => {
            const hasExceededLength = values.filter((value: any[]) => {
              return value.length > navigateFilterLimit;
            }).length > 0;
            if (hasExceededLength) {
              setToast({ open: true, text: 'Filtering should be applied which reduces number of rows to ' + navigateFilterLimit + ' to use this feature', messageType: 'info', title: 'Exceeded Filter Limit' });
            } else {
              let preFilter = {} as any;
              hasSetFilterMappers.forEach((mapper: any, i: number) => {
                // Gross mutation but todo fix it plz
                preFilter[mapper.target_attribute] = values[i];
              });
              const newNavigations = navigations.concat([{
                previousState,
                view: newView,
                preFilter
              }]);
              setNavigations(newNavigations);
            }
          }).catch(() => {
            setToast({ open: true, text: 'Error loading filter values for navigation.  Please try again.', messageType: 'error', title: 'Error navigating to selected view' });
          });
        };

        navigateWith(gridApi, params.columnApi, view, views, navigation, navigate, setToast);
      }
    }
  }) : [];
  const navigateWithFilteredRows: MenuItemDef = {
    name: 'Navigate with filtered rows',
    subMenu: navigateWithFilteredRowsSubmenuItems,
    disabled: view.navigations === undefined || view.navigations.length < 1
  };

  const contextMenuItems: Array<String | MenuItemDef> = [
    'copy',
    'copyWithHeaders',
    selectedRowExportOption,
    allRowsExportOption,
    allRowsWithHiddenExportOption,
    selectHighlightedRowsOption,
    deselectHighlightedRowsOption,
    navigateWithSelectedRows,
    navigateWithFilteredRows
  ];

  return contextMenuItems;
}

const processRowForRWFlyTo = (rowData: any[], view: ModelView, rw_placemark_list: Number[], setRwPlacemarkList: (arg: any) => void, iotConnection: mqtt.MqttClientConnection) => {
  const unique_id_col = View.getUniqueIdentifierColumn(view);
  const isPlacemarkAlreadyPresent: boolean = rw_placemark_list.indexOf(rowData[unique_id_col]) > -1;

  if (!isPlacemarkAlreadyPresent) {
    rw_placemark_list.slice().concat(rowData[unique_id_col]);
    setRwPlacemarkList(rw_placemark_list);
  }

  determineShapeType(rowData, view, isPlacemarkAlreadyPresent, iotConnection);
}

const determineShapeType = (rowData: any[], view: ModelView, isPlacemarkAlreadyPresent: boolean, iotConnection: mqtt.MqttClientConnection) => {
  switch (view.shape_type) {
    case SHAPE_TYPE.POINT:
      RWService.flyToPoint(rowData, view, isPlacemarkAlreadyPresent, iotConnection);
      break;

    case SHAPE_TYPE.LINE:
      RWService.flyToLine(rowData, view, isPlacemarkAlreadyPresent, iotConnection);
      break;

    case SHAPE_TYPE.WKT:
      RWService.flyToPolygon(rowData, view, isPlacemarkAlreadyPresent, iotConnection);
      break;

    default:
      console.warn(`FlyTo feature not available for Asset type ${view.shape_type}`);
      break;
  }
}

const getHiddenColumnList = (agGridColumnApi: ColumnApi): string[] => {
  return agGridColumnApi.getAllGridColumns().reduce(function (list: string[], col: any) {
    return col.visible ? list : list.concat([col.colId]);
  }, []);
}

const getOnSelectionChanged = (getSetTotalSelectedRowCount: () => (row: number) => void) => {
  return (event: SelectionChangedEvent) => {
    const setTotalSelectedRowCount = getSetTotalSelectedRowCount();
    setTotalSelectedRowCount(event.api.getSelectedNodes().length);
  };
};

const GridImplementation = {
  getSortModelFromColumnState,
  getUpdateFilterModel,
  getOnFirstDataRendered,
  getOnGridReady,
  getOnPaginationChanged,
  getOnFilterChanged,
  getOnBodyScroll,
  getOnRowClicked,
  getOnSelectionChanged,
  getDataSource,
  processRowForRWFlyTo,
  getMenuItems,
  exportDatGrid
};

export {
  GridImplementation
};
