import React, { MutableRefObject, RefObject } from "react";
import { AgGridReact } from "ag-grid-react";
import { TOOLTIP_TIMING } from "../../constants";
import { GridImplementation } from './GridImplementation';
import { IToolboxRef } from "./Toolbox";
import { IPageTopFiltersRef } from "./PageTopFilters";
import { IExportProgressDialogRef } from "./dialogs/ExportProgressDialog";
import { IValueUpdateBinRef } from "./ValueUpdateBin";
import { GridAttributes } from './GridAttributes';
import { ModelView } from "../../model/View";

const pageSize = 100;
const blockSize = 1000;

interface AgGridProps {
    profile: any;
    sessionId: string;
    pagination: boolean;
    gridRef: RefObject<AgGridReact<any>>;
    valueUpdateBinRef: RefObject<IValueUpdateBinRef | null>;
    toolboxRef: MutableRefObject<IToolboxRef | null>;
    pageTopFiltersRef: MutableRefObject<IPageTopFiltersRef | null>;
    exportProgressDialogRef: MutableRefObject<IExportProgressDialogRef | null>;
    fileDialogRef: any;
    token: string;
    view: ModelView;
    navigations: any;
    navigateToView: any;
    appToast: any;
    views: any;
    logOut: any;
    sortModel: any;
    filterModel: any;
    preFilter: any;
    columnState: any;
}

const AgGrid = ({
    profile,
    sessionId,
    pagination,
    gridRef,
    pageTopFiltersRef,
    valueUpdateBinRef,
    toolboxRef,
    exportProgressDialogRef,
    fileDialogRef,
    token,
    view,
    navigations,
    navigateToView,
    appToast,
    views,
    logOut,
    sortModel,
    filterModel,
    preFilter,
    columnState
}: AgGridProps) => {

    const NoOp = () => { };

    const getSetTotalRowCount = () => toolboxRef.current !== null ? toolboxRef.current.setTotalRowCount : NoOp;
    const getSetTotalPageCount = () => toolboxRef.current !== null ? toolboxRef.current.setTotalPageCount : NoOp;
    const getSetPageNumber = () => toolboxRef.current !== null ? toolboxRef.current.setPageNumber : NoOp;
    const getSetFirstDisplayedRow = () => toolboxRef.current !== null ? toolboxRef.current.setFirstDisplayedRow : NoOp;
    const getSetLastDisplayedRow = () => toolboxRef.current !== null ? toolboxRef.current.setLastDisplayedRow : NoOp;
    const getSetTotalSelectedRowCount = () => toolboxRef.current !== null ? toolboxRef.current.setTotalSelectedRowCount : NoOp;
    const getRwNavigateToRow = () => toolboxRef.current !== null ? toolboxRef.current.rwNavigateToRow : NoOp;

    const getActiveRowsIndex = () => toolboxRef.current !== null ? toolboxRef.current.activeRowsIndex : -1;

    const getSetFilterModel = () => pageTopFiltersRef.current !== null ? pageTopFiltersRef.current.setFilterModel : NoOp;

    const getSetProgress = () => exportProgressDialogRef.current !== null ? exportProgressDialogRef.current.setProgress : NoOp;

    const getSetFileDialogAttrs = () => fileDialogRef.current !== null ? fileDialogRef.current.setFileDialogAttrs : NoOp;

    const serverSideDatasource = GridImplementation.getDataSource(view, token, getSetTotalRowCount, appToast.set, preFilter, logOut);

    const onFilterChanged = GridImplementation.getOnFilterChanged(token, profile, sessionId, view.view_handle, getSetFilterModel, appToast.set);

    const onPaginationChanged = GridImplementation.getOnPaginationChanged(getSetPageNumber, getSetTotalPageCount, getSetFirstDisplayedRow, getSetLastDisplayedRow);
    const onFirstDataRendered = GridImplementation.getOnFirstDataRendered();
    const onGridReady = GridImplementation.getOnGridReady(sortModel, filterModel, columnState);
    const onBodyScroll = GridImplementation.getOnBodyScroll(getSetFirstDisplayedRow, getSetLastDisplayedRow);
    const onSelectionChanged = GridImplementation.getOnSelectionChanged(getSetTotalSelectedRowCount);
    const onRowClicked = GridImplementation.getOnRowClicked(getRwNavigateToRow);

    const columnDefs = GridAttributes.buildColDefs(token, view, getActiveRowsIndex, logOut);

    const getCache = () => {
        return pageTopFiltersRef.current?.cache
    };

    return <div style={{ flex: 1, marginLeft: 15, marginRight: 15 }} className="ag-theme-balham">
        <AgGridReact
            enableBrowserTooltips={true}
            ref={gridRef}
            serverSideDatasource={serverSideDatasource}
            columnDefs={columnDefs}
            readOnlyEdit={true}
            sideBar={{
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true,
                        },
                    },
                ],
                defaultToolPanel: 'columns',
            }}
            rowModelType='serverSide'
            defaultColDef={{ sortable: true }}
            rowSelection='multiple'
            rowMultiSelectWithClick={true}
            suppressRowClickSelection={true}
            pagination={pagination}
            suppressPaginationPanel={true}
            paginationPageSize={pageSize}
            cacheBlockSize={blockSize}
            blockLoadDebounceMillis={100}
            enableRangeSelection={true}
            enableFillHandle={true}
            enableCellChangeFlash={true}
            tooltipShowDelay={TOOLTIP_TIMING.Enter}
            /* getContextMenuItems isn't used here because it registers corresponding function during grid initialization and any update 
               doesn't get propagated to the function hence using onCellContextMenu which helps in displaying context menu on run time.
               Reference: https://github.com/ag-grid/ag-grid/issues/3518 */
            onCellContextMenu={
                (params: any) => {
                    const factory = params.api.contextMenuFactory;
                    const totalRowCount = toolboxRef.current !== null ? toolboxRef.current.totalRowCount : 0;
                    factory.getMenuItems = () => GridImplementation.getMenuItems(
                        token, view, getSetFileDialogAttrs, navigations, navigateToView,
                        appToast.set, views, getCache, logOut, params, totalRowCount, getSetProgress
                    ); // overridden method
                    factory.showMenu(params.node, params.column, params.value, params.event);
                }
            }
            onFilterChanged={onFilterChanged}
            // events
            onRowClicked={onRowClicked}
            onPaginationChanged={onPaginationChanged}
            onFirstDataRendered={onFirstDataRendered}
            onGridReady={onGridReady}
            onBodyScroll={onBodyScroll}
            onSelectionChanged={onSelectionChanged}
            onCellEditRequest={event => {
                if (valueUpdateBinRef.current !== null && event.colDef.field !== undefined){
                    const idKeys = view.attributes.filter((attribute) => {
                        return attribute.unique_identifier_field;
                    }).map((attribute) => {
                        return attribute.name;
                    });
                    const ids = idKeys.reduce((acc, value) => {
                        return {
                            ...acc,
                            [value]: event.data[value]
                        }
                    }, {});
                    valueUpdateBinRef.current.addRequest({
                        field: event.colDef.field,
                        rowIndex: event.rowIndex,
                        ids: ids,
                        value: event.newValue
                    })
                }
            }} />
    </div>
}

export {
    AgGrid
}