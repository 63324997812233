import { mqtt } from 'aws-iot-device-sdk-v2';
import {ALTITUDE_MODE, RWActionTypes, SHAPE_ATTRIBUTE, SHAPE_TYPE} from "../constants";
import View, {ModelView} from "../model/View";
import {IoTService} from "./IoTService";
import {GeoJSONGeometry, GeoJSONGeometryOrNull, GeoJSONPoint, parse} from 'wellknown';
import {GeoJSON} from 'geojson';
import * as getExtent from '@mapbox/geojson-extent'
import {isNullOrUndefined} from "../utils/helper";

interface LineCoordinates {
    NELat: number;
    NELon: number;
    SWLat: number;
    SWLon: number;
}

interface FlyToBounds extends LineCoordinates {
    MetresPadding: string;
    Time: string;
    AllowInterrupt: 'true' | 'false';
}

const flyToPoint = (data: any, view: ModelView, isPlacemarkAlreadyPresent: boolean, conn: any) => {
    const coordinates: any = getCoordinateColumns(view);

    const params = {
        Lat: data[coordinates.lat] + '',
        Lon: data[coordinates.long] + '',
        Alt: "100",
        Tilt: "90.0",
        Heading: "0.0",
        Roll: "0.0",
        Time: "5.0",
        AllowInterrupt: "true",
        AltitudeMode: ALTITUDE_MODE.RELATIVE_TO_GROUND
    };

    if (!isPlacemarkAlreadyPresent) {
        drawPlacemark(data, view, conn);
    }

    IoTService.publish(params, RWActionTypes.FlyTo, conn);
}

const flyToLine = (data: any, view: ModelView, isPlacemarkAlreadyPresent: boolean, conn: mqtt.MqttClientConnection) => {
    const coordinate_cols: any = getCoordinateColumns(view);

    const flyToBound_params: FlyToBounds = {
        NELat: Math.max(data[coordinate_cols.lat_start], data[coordinate_cols.lat_end]),
        NELon: Math.max(data[coordinate_cols.long_start], data[coordinate_cols.long_end]),
        SWLat: Math.min(data[coordinate_cols.lat_start], data[coordinate_cols.lat_end]),
        SWLon: Math.min(data[coordinate_cols.long_start], data[coordinate_cols.long_end]),
        MetresPadding: "10.0",
        Time: "5.0",
        AllowInterrupt: "true"
    };

    if (!isPlacemarkAlreadyPresent) {
        drawLine(data, view, conn);
    }

    IoTService.publish(flyToBound_params, RWActionTypes.FlyToBounds, conn);
}

const drawLine = (row: any, view: ModelView, conn: mqtt.MqttClientConnection) => {
    const unique_id_col = View.getUniqueIdentifierColumn(view);
    if (!unique_id_col) {
        console.warn("Unique identifier not found for shape type: Line");
    } else {
        const line_params = configureLineParams(row, unique_id_col, view);
        IoTService.publish(line_params, RWActionTypes.DrawLine, conn);
    }
}

const drawPlacemark = (row: any, view: ModelView, conn: any) => {
    const unique_id_col = View.getUniqueIdentifierColumn(view);
    if (!unique_id_col) {
        console.warn("Unique identifier not found for shape type: Point");
    } else {
        const placemark_params = configurePlacemarkParams(row, unique_id_col, view);
        IoTService.publish(placemark_params, RWActionTypes.DrawPlacemark, conn);
    }
}

const configurePlacemarkParams = (row: any, uniqueIdColumn: any, view: any) => {
    const coordinates: any = getCoordinateColumns(view);
    const styleColumn = getGISStyleColumn(view);

    // Building the style
    let style = null;
    if (styleColumn) {
        style = view.asset_geospatial_styles.find((item: any) => {
            return item.name === row[styleColumn];
        });
    }
    if (!style) {
        style = getDefaultAssetGeospatialStyle(view);
    }


    return {
        Id: row[uniqueIdColumn],
        Name: (view.gis_placemark_name_column && row[view.gis_placemark_name_column]) ? row[view.gis_placemark_name_column] : "",
        LatLon: row[coordinates.lat] + ' ' + row[coordinates.long],
        Colour: style ? style.color.toString() : "",
        IconUrl: style ? style.icon : "",
        AltitudeMode: ALTITUDE_MODE.CLAMP_TO_GROUND,
        Scale: 1.0,
        Group: view.display_name,
        Notify: false
    };
}

const configureLineParams = (row: any, uniqueIdColumn: string, view: any) => {

    const coordinate_col = getCoordinateColumns(view);

    // Building the style
    const styleColumn = getGISStyleColumn(view);
    const styleColumnValue = getStyleColumnValue(styleColumn, row);

    const styleIndex = view.asset_geospatial_styles.findIndex((item: any) => {
        return item.name.toLowerCase() === styleColumnValue.toLowerCase();
    });

    const style = styleIndex > -1 ? view.asset_geospatial_styles[styleIndex] : getDefaultAssetGeospatialStyle(view);

    return {
        Id: row[uniqueIdColumn],
        Name: (view.gis_placemark_name_column && row[view.gis_placemark_name_column]) ? row[view.gis_placemark_name_column] : "",
        LatLon: `${row[coordinate_col.lat_start]} ${row[coordinate_col.long_start]}, ${row[coordinate_col.lat_end]} ${row[coordinate_col.long_end]}`,
        Colour: style ? style.color.toString() : "",
        Width: 3.0,
        AltitudeMode: ALTITUDE_MODE.CLAMP_TO_GROUND,
        Group: view.display_name,
        Notify: false
    };
}

const getStyleColumnValue = (styleColumn: any, row: any) => {
    if (styleColumn && row[styleColumn] != null) {
        if (row[styleColumn].includes(",")) {
            return row[styleColumn].split(",")[0]
        } else {
            return row[styleColumn];
        }
    } else {
        return "default";
    }
};

const flyToPolygon = (data: any, view: ModelView, isPlacemarkAlreadyPresent: boolean, conn: mqtt.MqttClientConnection) => {
    const coordinate_col: string = getCoordinateColumns(view);
    const geoJson: GeoJSONGeometry | null = getGeoJson(data, coordinate_col);
    if (geoJson !== null && geoJson !== undefined) {
        const polygonCoordinates: LineCoordinates | null = getPolygonCoordinates(geoJson);
        if (polygonCoordinates === null) {
            console.error('Unable to find Coordinates for Polygon')
            return;
        }
        const flyToBound_params: FlyToBounds = {
            NELat: polygonCoordinates.NELat,
            NELon: polygonCoordinates.NELon,
            SWLat: polygonCoordinates.SWLat,
            SWLon: polygonCoordinates.SWLon,
            MetresPadding: "10.0",
            Time: "5.0",
            AllowInterrupt: "true"
        };
        drawPolygon(data, view, coordinate_col, conn);

        IoTService.publish(flyToBound_params, RWActionTypes.FlyToBounds, conn);
    }
}

const getGeoJson = (data: any, polygon_coordinate_col: string): GeoJSONGeometryOrNull => {
    try {
        const wkt = data[polygon_coordinate_col].split(';')[1]; // API returns EWKT, so need to split off SRID component
        return parse(wkt); // parse function of "wellknown" package returns GeoJSONGeometry
    } catch (error) {
        console.error(`Failed to convert WKT to GeoJson :: ${error}`)
        return null;
    }
}

const getPolygonCoordinates = (geoJson: GeoJSON): LineCoordinates | null => {
    try {
        const boundingBox: any = getExtent.bboxify(geoJson!) // coordinates will be in form [WSEN]
        return {
            SWLon: boundingBox.bbox[0],
            SWLat: boundingBox.bbox[1],
            NELon: boundingBox.bbox[2],
            NELat: boundingBox.bbox[3]
        };
    } catch (error) {
        console.error(`Failed to convert GeoJson to a Bounding Box :: ${error}`);
        return null;
    }
}

const drawPolygon = (row: any, view: ModelView, polygon_coordinate_col: any, conn: mqtt.MqttClientConnection) => {
    const unique_id_col = View.getUniqueIdentifierColumn(view);
    if (!unique_id_col) {
        console.warn("Unique identifier not found for shape type: WKT");
    } else {
        const draw_polygon_params = configurePolygonParams(row, unique_id_col, polygon_coordinate_col, view);
        IoTService.publish(draw_polygon_params, RWActionTypes.DrawPolygon, conn);
    }
}

const configurePolygonParams = (row: any, uniqueIdColumn: any, polygon_coordinate_col: any, view: any) => {
    const coordinates = getCoordinatesForDrawingPolygon(row, view, polygon_coordinate_col);

    // Building the style
    const styleColumn = getGISStyleColumn(view);
    let style = "255,255,255"; // Default styling for polygon
    const polygon_specific_style = getPolygonSpecificStyle(styleColumn, row, view);

    if (!isNullOrUndefined(polygon_specific_style)) {
        let normalStyle = polygon_specific_style.normal.find((el: any) => el["PolyStyle"] !== undefined);
        style = normalStyle.PolyStyle.color.toString();
    }

    return {
        Id: row[uniqueIdColumn],
        Name: (view.gis_placemark_name_column && row[view.gis_placemark_name_column]) ? row[view.gis_placemark_name_column] : "",
        FillColour: style,
        OutlineColour: style,
        HasOutline: true,
        LatLon: coordinates,
        AltitudeMode: ALTITUDE_MODE.CLAMP_TO_GROUND,
        Group: view.display_name,
        GroupId: view.display_name.replace(/\s+/g, '_'),
        Notify: false
    };
}

const getPolygonSpecificStyle = (styleColumn: any, row: any, view: any) => {
    if (styleColumn && row[styleColumn]) {
        const polygon_specific_style = view?.asset_geospatial_styles.find((item: any) => {
            return item.name.toLowerCase() === row[styleColumn].toLowerCase();
        });

        if (!isNullOrUndefined(polygon_specific_style)) {
            return polygon_specific_style;
        }
    }

    return getDefaultAssetGeospatialStyle(view);

}

const getCoordinatesForDrawingPolygon = (row: any, view: ModelView, polygon_coordinate_col: any) => {
    let p_coordinates = "";
    if (polygon_coordinate_col === null) {
        polygon_coordinate_col = View.getColumnNameFromViewDef("shape_attribute", view.shape_type, view);
    }

    const wkt = row[polygon_coordinate_col].split(';')[1]; // API returns EWKT, so need to split off SRID component
    const parsed = parse(wkt) as GeoJSONPoint | null;
    let geojson: any = parsed?.coordinates[0];
    if (wkt.includes("MULTIPOLYGON")) {
        geojson = geojson[0]
    }

    for (let cord of geojson) {
        p_coordinates = `${p_coordinates}${cord[1]} ${cord[0]},`;
    }
    p_coordinates = p_coordinates.slice(0, -1); // removing the comma present at the end of the string

    return p_coordinates;
}


const getGISStyleColumn = (view: any) => {
    return view.gis_placemark_style_column ? view.gis_placemark_style_column : "";
}

const getDefaultAssetGeospatialStyle = (view: any) => {
    return view.asset_geospatial_styles.find((item: any) => {
        return item.name.toLowerCase() === "default";
    });
}

const getCoordinateColumns = (view: ModelView) => {
    switch (view.shape_type) {
        case SHAPE_TYPE.POINT:
            return {
                lat: View.getColumnNameFromViewDef("shape_attribute", SHAPE_ATTRIBUTE.LATITUDE_START, view),
                long: View.getColumnNameFromViewDef("shape_attribute", SHAPE_ATTRIBUTE.LONGITUDE_START, view)
            }
        case SHAPE_TYPE.LINE:
            return {
                lat_start: View.getColumnNameFromViewDef("shape_attribute", SHAPE_ATTRIBUTE.LATITUDE_START, view),
                long_start: View.getColumnNameFromViewDef("shape_attribute", SHAPE_ATTRIBUTE.LONGITUDE_START, view),
                lat_end: View.getColumnNameFromViewDef("shape_attribute", SHAPE_ATTRIBUTE.LATITUDE_END, view),
                long_end: View.getColumnNameFromViewDef("shape_attribute", SHAPE_ATTRIBUTE.LONGITUDE_END, view),
            }
        case SHAPE_TYPE.WKT:
            return View.getColumnNameFromViewDef("shape_attribute", SHAPE_ATTRIBUTE.WKT, view);
        default:
            console.warn(`Shape type ${view.shape_type} not supported`)
            return {};
    }
}

export const RWService = {
    flyToPoint,
    flyToLine,
    flyToPolygon
}
