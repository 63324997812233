import React from 'react';
import ReactDOM from 'react-dom';
import './sass/theme.scss';
import './icomoon/style.css'
import { App } from './main/App';
import * as serviceWorker from './serviceWorker';
import { LicenseManager } from 'ag-grid-enterprise';

const licenceKey = `${process.env.REACT_APP_AG_GRID_LICENCE}`;
if (licenceKey) {
    LicenseManager.setLicenseKey(licenceKey);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
