import React from "react";
import { ModelView } from "../../model/View";
import Tooltip from '@mui/material/Tooltip';
import { ASSET_TYPES, TOOLTIP_TIMING, ViewType } from "../../constants";
import { useRouteMatch } from "react-router-dom";

const SlugSpacer = () => {
  return <div className='slug__spacer'>{">"}</div>;
};

interface SlugProps {
  view: ModelView;
}

const Slug = ({view}: SlugProps) => {
  const splittedRoute: string[] = useRouteMatch().path?.split('/');
  const viewTypeRoute: string = splittedRoute.length > 0 ? splittedRoute[1] : '';

  const viewType: ViewType | undefined = ASSET_TYPES.find((type: ViewType) => type.route === viewTypeRoute);

  const slugTags = view.tags !== undefined && view.tags !== null && view.tags.length > 0 ? view.tags : ['Other'];
  return <div className='slug' role='banner'>
    <div className='slug__section'>{viewType?.text}</div>
    <SlugSpacer />
    {
      slugTags.map((slugTag: string, i) => {
        return <div key={i} className='slug__category'>{slugTag}</div>;
      })
    }
    <SlugSpacer />
    <div className='slug__name'>
      {
        view.display_name
      }
    </div>
    <Tooltip title={view.description ?? "" } key={"tooltip_scroll_pagination_toggle"} placement="right" arrow enterDelay={TOOLTIP_TIMING.Enter} leaveDelay={TOOLTIP_TIMING.Leave}>
      <div className='slug__icon icon-info_outline'></div>
    </Tooltip>
  </div>;
};

export {
  Slug
}