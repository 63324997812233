import React from "react";

import Chip from "@mui/material/Chip";
import { ModelView } from "../../model/View";
import { isNullOrUndefined, getDateStringAsPerLocale } from "../../utils/helper";
import { FilterModel } from "./GridFiltering";
import { Events, logEvent } from "../../service/LoggingService";

interface FilterModelRepresentationProps {
  filterModel: FilterModel | null;
  getOnDelete: (key: string) => () => void;
  view: ModelView;
}

const agGridFiltersSentenceCaseEquivalents = {
  equals: "equals",
  greaterThan: "greater than",
  lessThan: "less than",
  notEqual: "not equals",
  contains: "contains",
  greaterThanOrEqual: "greater than or equals",
  lessThanOrEqual: "less than or equals",
  inRange: "in range",
  startsWith: 'starts with'
} as any;

// Function that takes a grid Ref and returns a function,
//          that takes a filterToRemove string and returns a function
//          that takes nothing and returns void after removing the entry from the filter model
const getOnDelete = (token: string, profile: any, sessionId: string, view_handle: string, gridRef: any) => (filterToRemove: string) => () => {
  const filterModel = gridRef.current.api.getFilterModel();
  const { [filterToRemove]: destroyed, ...rest } = filterModel;
  logEvent(token, profile, Events.RemoveFilterFromChip, {
    session_id: sessionId,
    view_handle,
    attr_name: filterToRemove
  });
  gridRef.current.api.setFilterModel(rest);
};

interface ChipWrapperProps {
  filterName: string;
  getOnDelete: (filterToRemove: string) => () => void;
  label: string;
}

const ChipWrapper = ({ filterName, getOnDelete, label }: ChipWrapperProps) => {
  return (
    <span className="activefilter">
      <Chip className="activefilter-button" onDelete={getOnDelete(filterName)} color="primary" label={label} />
    </span>
  );
};

const FilterModelRepresentation = ({
  filterModel,
  getOnDelete,
  view,
}: FilterModelRepresentationProps) => {
  const keys = filterModel !== null ? Object.keys(filterModel).filter((key: string) => {
    // Do not represent the current cycle indicator field with a chip
    return key !== "cycle_current_ind";
  }) : [];
  const filterBlocks = keys.map((key, i) => {
    const fM = filterModel as any;
    const value = fM[key];
    const displayName = view.attributes.find((col: any) => col.name === key)
      ?.display_name;
    if (value.filterType === "set") {
      const label = getFilterModelForSetFilterColumns(displayName, value.values);
      return (
        <ChipWrapper
          key={i}
          filterName={key}
          getOnDelete={getOnDelete}
          label={label}
        />
      );
    } else if (
      value.filterType === "text" ||
      value.filterType === "number" ||
      value.filterType === "date"
    ) {
      return getFilterModelForNonSetFilterColumns(
        value,
        displayName,
        key,
        i,
        getOnDelete
      );
    }
    return <></>;
  });

  return filterBlocks.length > 0 ? <div className="activefilterlist">{filterBlocks}</div> : <></>;
};

const getFilterModelForSetFilterColumns = (displayName: string, values: any) => {
  const nullFilters = values.findIndex((value: any) => isNullOrUndefined(value)) > -1 ? " blank" : ""
  const nonNullFilters = values.filter((value: any) => !isNullOrUndefined(value)).join(" or ");
  const joiner = values.findIndex((value: any) => isNullOrUndefined(value)) > -1 && values.length > 1 ? " or " : "";
  return `${displayName} is ` + nullFilters + joiner + nonNullFilters;
}

const getFilterModelForNonSetFilterColumns = (
  value: any,
  displayName: string,
  key: string,
  index: number,
  getOnDelete: (filterToRemove: string) => () => void
) => {
  const label = getLabel(value, displayName);
  return (
    <ChipWrapper
      key={index}
      filterName={key}
      getOnDelete={getOnDelete}
      label={label}
    />
  );
};

const getLabel = (value: any, displayName: string) => {
  const filterFrom = value.dateFrom
    ? getDateStringAsPerLocale(value.dateFrom)
    : value.filter;
  const filterTo = value.dateTo
    ? getDateStringAsPerLocale(value.dateTo)
    : value.filterTo;
  if (value.type === "inRange") {
    return `${displayName} Between ${filterFrom} and ${filterTo}`;
  } else if (value.type === "blanks") {
    return `${displayName} is blank`;
  } else if (value.type === "non-blanks") {
    return `${displayName} is not blank`;
  } else {
    return `${displayName} ${
      agGridFiltersSentenceCaseEquivalents[value.type]
    } ${filterFrom}`;
  }
};

export { FilterModelRepresentation, getOnDelete };
