export const isNullOrUndefined = <T>(value: T | undefined | null): value is null | undefined => { return value === null || value === undefined };

export const cleanString = (value: string | undefined): string => { 
    return isNullOrUndefined(value) ? '' : value;
};

export const stringStitcher = (toStitch: string[], seperator: string, initialString: string = ''): string => {
    if (toStitch.length === 0) {
      return initialString;
    } else if(initialString === '') {
      const currentString = cleanString(toStitch.shift());
      return stringStitcher(toStitch, seperator, currentString);
    } else {
      const currentString = initialString + seperator + toStitch.shift();
      return stringStitcher(toStitch, seperator, currentString);
    }
};

export const getDateStringAsPerLocale = (dateString: string): string => {
  const utcDate = new Date(dateString);
  return utcDate.toLocaleDateString();
}

export const getDateTimeStringAsPerLocale = (dateTimeString: string): string => {
  const utcDate = new Date(dateTimeString);
  return utcDate.toLocaleString();
}

export const calculateProgressInPercentage = (partsCompleted: number, totalparts: number) => {
  return Math.pow(partsCompleted/totalparts, 1.25) * 100;
}