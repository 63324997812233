import React, { MutableRefObject, RefObject, useState, useEffect } from "react";

import { AgGridReact } from "ag-grid-react";
import { ToastAttributes } from "../common/Toast";
import { ModelView } from "../../model/View";
import { ViewDataService } from "../../service/ViewDataService";

export interface ValueUpdateRequest {
    field: string;
    rowIndex: number | null;
    ids: Record<string, string | number>;
    value: any;
}

export interface IndexedValueUpdateRequest {
    field: string;
    rowIndex: number;
    ids: Record<string, string | number>;
    value: any;
}

export interface IValueUpdateBinRef {
    addRequest: (request: ValueUpdateRequest) => void;
};

interface ValueUpdateBinProps {
    view: ModelView;
    profile: any;
    token: string;
    gridRef: RefObject<AgGridReact<any>>;
    valueUpdateBinRef: MutableRefObject<IValueUpdateBinRef | null>;
    setToast: (args: ToastAttributes) => void;
}

const debounceMillis = 25;

const ValueUpdateBin = ({
    view,
    profile,
    token,
    gridRef,
    valueUpdateBinRef,
    setToast
}: ValueUpdateBinProps) => {
    const [requests, setRequests] = useState<ValueUpdateRequest[]>([]);
    const [debouncedRequests, setDebouncedRequests] = useState<ValueUpdateRequest[]>([]);

    const sendRequests = (requests: ValueUpdateRequest[]) => {
        return new Promise((resolve: (value: ValueUpdateRequest[]) => void) => {
            if (gridRef.current) {
                gridRef.current.api.showLoadingOverlay();
            }
            const ids = requests.map((request) => {
                return request.ids;
            });

            const fieldValues = requests.reduce((acc, request) => {
                return acc.hasOwnProperty(request.field) ? acc :
                    {
                        ...acc,
                        [request.field]: request.value
                    };
            }, {});

            ViewDataService.putViewData(token, view.view_handle, fieldValues, ids).then((response: any) => {
                resolve((requests)); // TODO: make this eat the responses instead of just assuming the requsts are right
            });
        });
    };

    const hideLoadingOverlay = () => {
        if (gridRef.current) {
            gridRef.current.api.hideOverlay();
        }
    };

    const updateRows = (responses: ValueUpdateRequest[]) => {
        console.log(`Fired Event for Items with Aurora Keys: [${responses.map(request => JSON.stringify(request.ids)).join(', ')}] with new Value: ${responses[0].value} for Column: ${responses[0].field} for User: ${profile.user_id}`);
        if (gridRef.current !== null) {
            const gridApi = gridRef.current.api;
            const indexedResponses = responses.filter((response): response is IndexedValueUpdateRequest => response.rowIndex !== null);

            indexedResponses.forEach((response) => {
                const row = gridApi.getDisplayedRowAtIndex(response.rowIndex);

                const data = row?.data;

                if (row && data) {
                    row.updateData({
                        ...data,
                        [response.field]: response.value
                    })
                }
            })
        }

        return responses;
    };

    const addRequest = (request: ValueUpdateRequest) => {
        if (requests.length > 0) {
            // Check that the values match between all requests we have and the one we're adding
            const nonMatchingFieldValue = requests.find((req) => {
                return req.value !== request.value && req.field === request.field;
            });
            if (nonMatchingFieldValue !== undefined) {
                sendRequests(requests).then(updateRows).then((requests: ValueUpdateRequest[]) => {
                    setRequests([request]);
                }).finally(hideLoadingOverlay);
            } else {
                setRequests((requests) => requests.concat([request]))
            }
        } else {
            setRequests((requests) => requests.concat([request]));
        }
    };

    valueUpdateBinRef.current = { addRequest };

    // Use effect to handle debouncing of requests
    useEffect(() => {
        const handler = requests.length > 0 ? setTimeout(() => {
            setDebouncedRequests(requests);
            setRequests([]);
        }, debounceMillis) : null;

        return () => {
            if (handler !== null) {
                clearTimeout(handler);
            }
        };
    }, [requests]);

    // Handle requests here
    useEffect(() => {
        if (debouncedRequests.length > 0) {
            sendRequests(debouncedRequests).then(updateRows).then((requests: ValueUpdateRequest[]) => {
                setDebouncedRequests([]);
            }).finally(hideLoadingOverlay);
        }
    }, [debouncedRequests, hideLoadingOverlay, updateRows])


    return <></>;
};

export { ValueUpdateBin };
