import React, { useEffect, useContext } from 'react';
import { ViewService } from "../../service/ViewService";
import View, { ModelView } from '../../model/View';
import AppContext from '../../context/AppContext';
import { ViewCategory, ViewsState } from './ViewCategory';
import { Auth } from '../../auth/Auth';
import { isNullOrUndefined } from '../../utils/helper';

export interface ViewListProps {
  activeSection: string | null;
  expanded: boolean;
  views: ViewsState;
  viewHandle?: string;
  setViews: (views: ViewsState) => void;
  resetGridAndSetView: (view: ModelView) => void;
}

const getData = (token: string, views: ViewsState, setViews: (store: ViewsState) => void, logOut: () => void) => {
  ViewService.getViewDefinitions(token, logOut).then((result) => {
    const categorizedViews = View.categorizeViews(result.result);
    // Instead of mutating state, we instead overwrite the values of exactly what we need
    // by exploding the current store into a new object and then overwriting
    // the data property with a new Option.some
    const newViews = {
      ...views,
      data: categorizedViews,
      uncategorizedViews: result.result
    };
    // Then set the whole store again as one setStore call
    setViews(newViews);
  }).catch(() => {
    // TODO: Proper error handling after adding Auth0 properly
    console.error('Views API Broken, CHECK YO TOKEN');
  });
};

interface ViewListStuffingProps {
  activeSection: string | null;
  views: ViewsState;
  viewHandle?: string;
  setViews: (views: ViewsState) => void;
  resetGridAndSetView: (view: ModelView) => void;
  refresh: () => void;
}

const ViewListStuffing = ({ activeSection, views, setViews, viewHandle, resetGridAndSetView, refresh }: ViewListStuffingProps) => {
  if (views.data === undefined) {
    return <div className="viewlist__loading">Loading... <div onClick={refresh}>Refresh</div></div> // If we have no data, display loading
  } else {
    const data = views.data;
    const keys = isNullOrUndefined(activeSection) ? [] : Object.keys(data).filter((x => x.match(activeSection))); //Filter list of displayed views based on section selected
    if (keys.length > 0) {
      const sorted = keys.sort();
      const sortedWithData = sorted.map((key) => {
        return {
          key,
          value: data[key]
        };
      });
      return <div>
        {
          sortedWithData.map((category: any, i: any) => {
            const isCategoryOpen = category.value.some((view: any) => view.view_handle === viewHandle) || views.openCategories.includes(i) || (keys.length === 1);
            return <ViewCategory
              key={i}
              i={i}
              views={views}
              setViews={setViews}
              data={category}
              viewHandle={viewHandle}
              isCategoryOpen={isCategoryOpen}
              resetGridAndSetView={resetGridAndSetView} />
          })
        }
      </div>
    } else {
      return <div>No {activeSection} Grids are Available to your Account</div>
    }
  }
};

const ViewList = ({ activeSection, expanded, views, setViews, viewHandle, resetGridAndSetView }: ViewListProps) => {

  const { appIdToken, appAccessToken, appProfile, appIsPersistingUrl } = useContext(AppContext);
  const logOut = Auth.getLogout(appAccessToken.set, appIdToken.set, appProfile.set, appIsPersistingUrl.set, true);

  const refresh = () => {
    const token = appIdToken.get();
    if (token) {
      getData(appIdToken.get(), views, setViews, logOut);
    }
  };

  useEffect(() => {
    // If we have Option.none data, let's make that Option.some data
    if (views.data === undefined) {
      refresh();
    }
  });

  const stuffing = <ViewListStuffing activeSection={activeSection} views={views} setViews={setViews} viewHandle={viewHandle} resetGridAndSetView={resetGridAndSetView} refresh={refresh} />

  return <div className={expanded ? 'viewlist viewlist--expanded' : 'viewlist'}>
    {expanded ? stuffing : <></>}
  </div>;
};

export default ViewList;