const numeral = require('numeral');

const cell_formatters = [
    {
        name: "0%",
        description: "0.53333 > 53%",
        default_for_numeric_type: null,
        formatter_function: (value: any) => {
            return value != null ? numeral(value).format('0%') : null;
        }
    },
    {
        name: "0.00%",
        description: "0.53333 > 53.33%",
        default_for_numeric_type: null,
        formatter_function: (value: any) => {
            return value != null ? numeral(value).format('0.00%') : null;
        }
    },
    {
        name: "0% Hundreds",
        description: "53.3333 > 53%",
        default_for_numeric_type: null,
        formatter_function: (value: any) => {
            return value != null ? numeral(value / 100).format('0%') : null;
        }
    },
    {
        name: "0.00% Hundreds",
        description: "53.3333 > 53.33%",
        default_for_numeric_type: null,
        formatter_function: (value: any) => {
            return value != null ? numeral(value / 100).format('0.00%') : null;
        }
    },
    {
        name: "0.00",
        description: "9753.3333 > 9753.33",
        default_for_numeric_type: null,
        formatter_function: (value: any) => {
            return value != null ? numeral(value).format('0.00') : null;
        }
    },
    {
        name: "0,0",
        description: "9753.3333 > 9,753",
        default_for_numeric_type: null,
        formatter_function: (value: any) => {
            return value != null ? numeral(value).format('0,0') : null;
        }
    },
    {
        name: "0,0.00",
        description: "9753.3333 > 9,753.33",
        default_for_numeric_type: "Float",
        formatter_function: (value: any) => {
            return value != null ? numeral(value).format('0,0.00') : null;
        }
    }
];

const GridFormatters = {
    cell_formatters
};

export {
    GridFormatters
}