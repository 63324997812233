import React from "react";
import Button from '@mui/material/Button';
interface PaginationControlsProps {
  pagination: boolean;
  pageNumber: number;
  totalPageCount: number;
  onPageFirst: () => void;
  onPagePrev: () => void;
  onGoToPage: (pageNumber: number) => void;
  onPageNext: () => void;
  onPageLast: () => void;
}

const PaginationControls = ({ pagination, pageNumber, totalPageCount, onPageFirst, onPagePrev, onGoToPage, onPageNext, onPageLast }: PaginationControlsProps) => {
  
  const requestPageCount = 3;

  const getPageRange = (numOfPagesToShow: number, currentPage: number, totalPageCount: number) => {
    if(numOfPagesToShow > totalPageCount){
      numOfPagesToShow = totalPageCount;
    }
    if (currentPage + numOfPagesToShow >= totalPageCount) {
      return Array.from({length: numOfPagesToShow}, (_, i) => (totalPageCount - numOfPagesToShow) + i)
    } else if (currentPage < numOfPagesToShow){
      return Array.from({length: numOfPagesToShow}, (_, i) => (0 + i))
    } else {
      return Array.from({length: numOfPagesToShow}, (_, i) => ((currentPage - Math.floor(numOfPagesToShow/2)) + i))
    }
  }

  if (pagination) {
    return <div className = 'toolbox_middle'>
      <Button className={`paginationNavigationButton ${pageNumber === 0 ? 'disabled' : ''}`} onClick = {onPageFirst}><i className = 'icon-ic_last_page_24px' style = {{transform: 'rotate(180deg)'}}/></Button>
      <Button className={`paginationNavigationButton ${pageNumber === 0 ? 'disabled' : ''}`} onClick = {onPagePrev}><i className = 'icon-ic_chevron_right_24px' style = {{transform: 'rotate(180deg)'}}/></Button>
      <div className='paginationTextBox'>
        <Button className={`paginationNumberButton`} disabled>Page</Button>
        {(pageNumber >= requestPageCount) ? <Button className={`paginationDotsButton`} disabled><i className = 'paginationButton_Dotsicon icon-ic_ellipsis'/></Button> : <></> }        
        {getPageRange(requestPageCount, pageNumber, totalPageCount).map((selectedPageNum) => {
          return <Button className={`paginationNumberButton ${selectedPageNum === pageNumber ? 'currentPage' : ''}`} onClick = {() => {onGoToPage(selectedPageNum)}}>{selectedPageNum+1}</Button>
        })}
        {(pageNumber + requestPageCount < totalPageCount) ? <Button className={`paginationDotsButton`} disabled><i className = 'paginationButton_Dotsicon icon-ic_ellipsis'/></Button> : <></> }
      </div>
      <Button className={`paginationNavigationButton ${pageNumber + 1 === totalPageCount ? 'disabled' : ''}`} onClick = {onPageNext}><i className = 'icon-ic_chevron_right_24px'/></Button>
      <Button className={`paginationNavigationButton ${pageNumber + 1 === totalPageCount ? 'disabled' : ''}`} onClick = {onPageLast}><i className = 'icon-ic_last_page_24px'/></Button>
    </div>
  } else {
    return <></>;
  }
};

export {
  PaginationControls
};
