import React from "react";
import { Signout } from "../common/sidenav/Signout";
import Tooltip from "@mui/material/Tooltip";
import { ViewType, ASSET_TYPES, TOOLTIP_TIMING } from "../../constants";
import { useRouteMatch } from 'react-router-dom';

interface SectionsProps {
  activateSection: (selectedSection: ViewType) => void;
  signOut: () => void;
}

const Sections = ({
  activateSection,
  signOut,
}: SectionsProps) => {
  const { params }  = useRouteMatch() as any;
  return (
    <div className="sections" key="sections">
      {ASSET_TYPES.map((section: ViewType) => {
        return (
          <Tooltip
            title={section.text}
            key={"tooltip_" + section.text}
            placement="right"
            enterDelay={TOOLTIP_TIMING.Enter}
            leaveDelay={TOOLTIP_TIMING.Leave}
          >
            <div
              key={section.text}
              className={`section ${params.viewType ===  section.route ? 'selected' : ''}`}
              onClick={() => activateSection(section)}
            >
              <div className={`icon icon-${section.icon}`} />
            </div>
          </Tooltip>
        );
      })}
      <Signout signOut={signOut} />
    </div>
  );
};

export { Sections };
