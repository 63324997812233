import View, {ModelView} from '../../model/View';
import {IError} from '../../service/ServiceBase';
import {ViewDataService} from '../../service/ViewDataService';
import {ToastAttributes} from '../common/Toast';
import {isNullOrUndefined} from "../../utils/helper";

export type FilterModel = Record<string, any>;

const operators = {
  'equals': 'eq',
  'greaterThan': 'gt',
  'lessThan': 'lt',
  'notEqual': 'ne',
  'contains': 'like',
  'greaterThanOrEqual': 'ge',
  'lessThanOrEqual': 'le',
  'inRange': 'range'
} as any;

const defaultCycleCurrentInd = {
  type: 'set',
  values: ['true']
};

const COLUMNFILTER = 'Column Filter';
const COLUMNPICKLIST = 'Column Pick List';
const PAGETOPICKLIST = 'Page Top Picklist';

const blankValuesFilter = {
  displayKey: 'blanks',
  displayName: 'Show blank values',
  test: function (filterValue: any, cellValue: any) {
    // this method won't get executed since actual filtering takes place on server side. Including this method here just to prevent type errors
    return true
  },
  hideFilterInput: true,
};

const nonBlankValuesFilter = {
  displayKey: 'non-blanks',
  displayName: 'Hide blank values',
  test: function (filterValue: any, cellValue: any) {
    // this method won't get executed since actual filtering takes place on server side. Including this method here just to prevent type errors
    return true
  },
  hideFilterInput: true,
};

const allFilters = [
  'equals',
  'notEqual',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'inRange',
  blankValuesFilter,
  nonBlankValuesFilter,
];

const extractModelEntry = (filterModel: FilterModel, key: string) => {
  const modelEntry = filterModel[key];
  if (modelEntry.filterType === 'set') {
    return constructModelEntryForSetFilter(modelEntry, key);
  } else {
    if (modelEntry.filterType === 'text') {
      return constructModelEntryForTextFilter(modelEntry, key);
    } else if (modelEntry.filterType === 'date') {
      return constructModelEntryForDateFilter(modelEntry, key);
    } else if (modelEntry.filterType === 'number') {
      return constructModelEntryForNumberFilter(modelEntry, key);
    }
    // If we somehow have something other than we expect, return an empty array to prevent something bad from happening.
    return [];
  }
};

const constructModelEntryForSetFilter = (modelEntry: any, key: string): string[] => {
  const nullPresentFilter = modelEntry.values.indexOf(null) > -1 ? `${key} eq null` : ``;
  const stripUndefinedModelEntryValues = modelEntry.values.filter((value: any) => value !== undefined); //Currently doesnt allow NULL to be passed in multiple filters due to Datasource restriction
  if (modelEntry.values.length === 1 && nullPresentFilter !== '') {
    return [nullPresentFilter]; // Return an array as we're Array binding here
  } else if (modelEntry.values.length === 1) {
    return [`${key} eq '${modelEntry.values[0]}'`]; // Return an array as we're Array binding here  
  } else {
    const modelValues = stripUndefinedModelEntryValues.map((value: any) => value === null ? `${value}`: `'${value}'`).join(',');
    return modelValues.length > 0 ? [`${key} in [${modelValues}]`] : []; // If we have no values, don't treat this as a filter
  }
}

const constructModelEntryForTextFilter = (modelEntry: any, key: string): string[] => {
  const operator = operators[modelEntry.type];
  if (modelEntry.type === 'blanks') {
    return [`${key} eq null`];
  } else if (modelEntry.type === 'non-blanks') {
    return [`${key} ne null`];
  } else if (modelEntry.type === 'startsWith') {
    // this approach uses ascii values of the characters to find strings starting with some specific characters. 
    const latestAlphaNumericCharacter: string = 'z';
    return [`${key} ${operators['greaterThanOrEqual']} '${modelEntry.filter}' and ${key} ${operators['lessThanOrEqual']} '${modelEntry.filter}${latestAlphaNumericCharacter}'`];
  } else {
    return [`${key} ${operator} '${modelEntry.filter}'`];
  }
}

const constructModelEntryForDateFilter = (modelEntry: any, key: string): string[] => {
  const operator = operators[modelEntry.type];
  const dateFrom = modelEntry.dateFrom;
  if (modelEntry.type === 'inRange') {
    const dateTo = modelEntry.dateTo;
    return [`${operator}(${key}, '${dateFrom}', '${dateTo}')`];
  } else if (modelEntry.type === 'blanks') {
    return [`${key} eq null`];
  } else if (modelEntry.type === 'non-blanks') {
    return [`${key} ne null`];
  } else {
    return [`${key} ${operator} '${dateFrom}'`];
  }
}

const constructModelEntryForNumberFilter = (modelEntry: any, key: string): string[] => {
  const operator = operators[modelEntry.type];
  if (modelEntry.type === 'inRange') {
    return [`${operator}(${key}, ${modelEntry.filter}, ${modelEntry.filterTo})`];
  } else if (modelEntry.type === 'blanks') {
    return [`${key} eq null`]
  } else if (modelEntry.type === 'non-blanks') {
    return [`${key} ne null`]
  } else {
    return [`${key} ${operator} '${modelEntry.filter}'`]
  }
}

const constructFilterStringFromFilterModel = (filterModel: FilterModel) => {
  const keys = Object.keys(filterModel);
  const filterEntries = keys.map((key: string) => {
    return extractModelEntry(filterModel, key);

  });
  return filterEntries.flat().join(' and ');
};

const extractPrefilterEntry = (filterModel: FilterModel, key: string) => {
  const modelEntry = filterModel[key];
  if (modelEntry.values.length === 1) {
    return [`${key} eq '${modelEntry[0]}'`]; // Return an array as we're Array binding here
  } else {
    const modelValues = modelEntry.map((value: string) => `'${value}'`).join(',');
    return modelValues.length > 0 ? [`${key} in [${modelValues}]`] : []; // If we have no values, don't treat this as a filter
  }
};

const constructFilterStringFromFilterModelWithPrefilter = (preFilter: any, filterModel: FilterModel) => {
  const filterString = constructFilterStringFromFilterModel(filterModel);
  const keys = Object.keys(preFilter);
  const preFilterEntries = keys.map((key: string) => {
    return extractPrefilterEntry(preFilter, key);
  });
  const preFilterString = preFilterEntries.flat().join(' and ');
  return preFilterString.length > 0 && filterString.length > 0 ? `${filterString} and ${preFilterString}` : `${filterString}${preFilterString}`;
};

const constructFilterStringFromFilterModelWithExclude = (filterModel: FilterModel, excluded: string) => {
  const keys = Object.keys(filterModel);
  const filterEntries = keys.map((key: string) => {
    if (key === excluded) {
      return [];
    } else {
      return extractModelEntry(filterModel, key);
    }
  });
  return filterEntries.flat().join(' and ');
};

const attributeHasFilter = (attribute: any) => {
  return attribute.hasOwnProperty('filter_type') && attribute.filter_type.length > 0;
};

const attributeHasPageTopFilter = (attribute: any) => {
  return attribute.filter_type === PAGETOPICKLIST;
};

const attributeHasSetFilter = (attribute: any) => {
  return attributeHasPageTopFilter(attribute) || attribute.filter_type === COLUMNPICKLIST;
};

const attributeHasTextFilter = (attribute: any) => {
  return attribute.filter_type === COLUMNFILTER && attribute.primitive_type === 'String';
};

const attributeHasDateFilter = (attribute: any) => {
  return attribute.filter_type === COLUMNFILTER && (
    attribute.primitive_type === 'Date' ||
    attribute.primitive_type === 'Date/Time');
};

const attributeHasNumberFilter = (attribute: any) => {
  return attribute.filter_type === COLUMNFILTER && (
    attribute.primitive_type === 'Integer' ||
    attribute.primitive_type === 'Float' ||
    attribute.primitive_type === 'Long');
};

const attributeHasBooleanFilter = (attribute: any) => {
  return attribute.filter_type === COLUMNFILTER && attribute.primitive_type === 'Boolean';
};

const attributesContainsCycleSelector = (attributes: any[]) => {
  return attributes.findIndex((attribute: any) => {
    return attribute.name === 'cycle_current_ind' &&
      attribute.filter_type === COLUMNFILTER &&
      attribute.primitive_type === 'Boolean' &&
      attribute.display !== 'Excluded';
  }) > -1;
};

const getValueSetFilter = (token: string, view: ModelView, attribute: any, logOut: () => void) => {
  return {
    filter: 'agSetColumnFilter',
    filterParams: {
      values: (params: any) => {
        ViewDataService.getDistinctValues(token, view.view_handle, 10000, attribute.name, '*/*', logOut)
          .then((response: any) => {
            const values = response.rows.map((row: any) => row[attribute.name]);
            params.success(values);
          });
      },
      defaultToNothingSelected: true,
      buttons: ['clear', 'apply'],
      closeOnApply: true
    }
  }
};

const getTextFilter = () => {
  return {
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals', 'notEqual', 'contains', 'startsWith', blankValuesFilter, nonBlankValuesFilter],
      caseSensitive: true,
      suppressAndOrCondition: true
    }
  }
};

const getDateFilter = () => {
  return {
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: allFilters,
      suppressAndOrCondition: true
    }
  }
};

const getNumberFilter = () => {
  return {
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: allFilters,
      suppressAndOrCondition: true
    }
  }
};

const getBooleanFilter = () => {
  return {
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['true', 'false'],
      newRowsAction: 'keep'
    }
  }
};

const getAttributeFilter = (token: string, view: ModelView, attribute: any, logOut: () => void) => {
  if (attributeHasFilter(attribute)) {
    if (attributeHasSetFilter(attribute)) {
      return getValueSetFilter(token, view, attribute, logOut);
    } else if (attributeHasTextFilter(attribute)) {
      return getTextFilter()
    } else if (attributeHasDateFilter(attribute)) {
      return getDateFilter();
    } else if (attributeHasNumberFilter(attribute)) {
      return getNumberFilter();
    } else if (attributeHasBooleanFilter(attribute)) {
      return getBooleanFilter();
    } else {
      return {};
    }
  } else {
    return {};
  }
};

const updateCacheAttrField = <T, K extends keyof T>(cache: T, attrName: K, fieldName: string, value: any): T => {
  return {
    ...cache,
    [attrName]: {
      ...cache[attrName],
      [fieldName]: value
    }
  };
};

const fixResponse = (rows: any[], attrName: string) => {
  const nonNullValues = rows.filter((row: any) => {
    const value = row[attrName];
    return value !== null && value !== undefined;
  });
  const values = nonNullValues.map((row: any) => row[attrName]);
  return values;
};

const fetchValues = (token: string, view: ModelView, attrName: string, active: boolean, 
  cache: Cache, setCache: (cache: Cache) => void, setToast: (args: ToastAttributes) => void, logOut: () => void) => {
  ViewDataService.getDistinctValues(token, view.view_handle, 10000, attrName, '*/*', logOut)
    .then((response: any) => {
      if (response && response.rows !== undefined) {
        if (active) {
          const nuVals = fixResponse(response.rows, attrName);
          const newCache = updateCacheAttrField(cache, attrName, 'values', nuVals);
          setCache(newCache);
        }
      } else {
        console.log('invalid response');
      }
    }, ((e: IError) => {
      setToast({ open: true, text: e.message, messageType: 'error', title: e.error ? e.error : 'Error in fetching data' });
    }));
};

const getFetchValuesPromise = (token: string, view: ModelView, attrName: string, 
  cache: Cache, setToast: (args: ToastAttributes) => void, logOut: () => void) => {
  return new Promise((resolve, reject) => {
    if (cache[attrName].values !== undefined) {
      resolve(cache[attrName].values);
    } else {
      ViewDataService.getDistinctValues(token, view.view_handle, 10000, attrName, '*/*', logOut)
        .then((response: any) => {
          if (response && response.rows !== undefined) {
            const nuVals = fixResponse(response.rows, attrName);
            resolve(nuVals);
          } else {
            reject('Active was false');
          }
        }, ((e: IError) => {
          const title = e.error ? e.error : 'Error in fetching data';
          setToast({ open: true, text: e.message, messageType: 'error', title });
          reject(`Failed due to: ${title}`);
        }));
    }
  })
};

const setOpen = (attrName: string, open: boolean, cache: Cache, setCache: (cache: Cache) => void): void => {
  const newCache = updateCacheAttrField(cache, attrName, 'open', open);
  setCache(newCache);
};

interface CacheEntry {
  displayName: string;
  values: string[] | undefined;
  fetchValues: (active: boolean, cache: Cache, setCache: (cache: Cache) => void, setToast: (args: ToastAttributes) => void) => void, logOut: () => void;
  getValuesPromise: (filterModel: FilterModel, cache: Cache, setToast: (args: ToastAttributes) => void) => Promise<any>;
  open: boolean;
  setOpen: (open: boolean, cache: Cache, setCache: (cache: Cache) => void) => void;
}

export type Cache = Record<string, CacheEntry>;

const buildAttributeFilterCache = (token: string, view: ModelView, logOut: () => void): Cache => {
  return view.attributes.reduce((acc: Cache, attribute: any): Cache => {
    if (View.isExcludedAttribute(attribute) || !attributeHasPageTopFilter(attribute)) {
      return acc; // If excluded, return the accumulator.
    }

    return {
      ...acc,
      [attribute.name]: {
        displayName: attribute.display_name,
        values: undefined,
        fetchValues: (active: boolean, cache: Cache, setCache: (cache: Cache) => void, setToast: (args: ToastAttributes) => void) => {
          fetchValues(token, view, attribute.name, active, cache, setCache, setToast, logOut);
        },
        getValuesPromise: (cache: Cache, setToast: (args: ToastAttributes) => void) => {
          return getFetchValuesPromise(token, view, attribute.name, cache, setToast, logOut)
        },
        open: false,
        setOpen: (open: boolean, cache: Cache, setCache: (cache: Cache) => void) => {
          setOpen(attribute.name, open, cache, setCache);
        }
      }
    };
  }, {});
};

const generateFilterForSelectedKMLExport = (gridOptions: any, selfKeys: any[]): any => {
  const rows = gridOptions.getSelectedRows();
  const self_key_name = selfKeys[0].name;

  const filteredRows = rows.map((row: any) => {
    return row[self_key_name];
  });

  /*JSON.stringify adds double quote around the values in the array so replacing them
  with single quotes to cater for view api expectations*/
  const stringifiedFilterQueryString = JSON.stringify(filteredRows).replace(/"/g, '\'');

  return `${self_key_name} in ${stringifiedFilterQueryString}`;
}

const GridFiltering = {
  attributesContainsCycleSelector,
  constructFilterStringFromFilterModel,
  constructFilterStringFromFilterModelWithPrefilter,
  constructFilterStringFromFilterModelWithExclude,
  updateCacheAttrField,
  attributeHasFilter,
  getAttributeFilter,
  buildAttributeFilterCache,
  generateFilterForSelectedKMLExport
};

export {
  GridFiltering,
  defaultCycleCurrentInd
}
