import React, { Dispatch, MutableRefObject, SetStateAction, useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { CircularProgressWithLabel } from "../../common/CircularProgressWithLabel";

export interface IExportProgressDialogRef {
    progress: number;
    setProgress: Dispatch<SetStateAction<number>>;
  };

interface ExportProgressDialogProps {
    exportProgressDialogRef: MutableRefObject<IExportProgressDialogRef | null>;
}

const ExportProgressDialog = ({exportProgressDialogRef}: ExportProgressDialogProps) => {
    const [progress, setProgress] = useState<number>(-1);

    exportProgressDialogRef.current = {progress, setProgress};

    return <Dialog aria-labelledby="simple-dialog-title" open={progress >= 0 && progress <= 100} fullWidth={true} maxWidth={'xs'}>
    <DialogTitle id="simple-dialog-title">Export in progress</DialogTitle>
    <DialogContent>
      <div className="progress-indicator-wrapper">
        <CircularProgressWithLabel value={progress} />
      </div>
    </DialogContent>
  </Dialog>;
};

export {
    ExportProgressDialog
}