import React from "react";
import Button from "@mui/material/Button";
interface RWNavigationProps {
  isRwNavEnabled: boolean;
  totalSelectedRowCount: number;
  rowActiveUp: () => void;
  rowActiveDown: () => void;
  rowSelectedUp: () => void;
  rowSelectedDown: () => void;
}

const RWNavigation = ({
  isRwNavEnabled,
  totalSelectedRowCount,
  rowActiveUp,
  rowActiveDown,
  rowSelectedUp,
  rowSelectedDown,
}: RWNavigationProps) => {
  if (isRwNavEnabled) {
    return (
      <div className="toolbox__right">
        <div className="active_RW_Navigation">
          <div className="RW_navigation-textBox">
            <div className="RW_navigation-text">
              <div className="RW_navigation-text-ellipsis">Active</div>
            </div>
          </div>
          <div className="RW_navigation-button-case">
            <Button className={`RW_navigation-button`} onClick={rowActiveUp}>
              <i className="icon-arrow_up" />
            </Button>
          </div>
          <div className="RW_navigation-button-case">
            <Button className={`RW_navigation-button`} onClick={rowActiveDown}>
              <i className="icon-arrow_down" />
            </Button>
          </div>
        </div>
        <div className="selected_RW_Navigation">
          <div className="RW_navigation-textBox">
            <div className="RW_navigation-text">
              <div className={totalSelectedRowCount === 0 ? 'RW_navigation-text-ellipsis disabled' : 'RW_navigation-text-ellipsis'}>Selected</div>
            </div>
          </div>
          <div className="RW_navigation-button-case">
            <Button className={`RW_navigation-button`} onClick={rowSelectedUp} disabled={totalSelectedRowCount === 0}>
              <i className="icon-arrow_up" />
            </Button>
          </div>
          <div className="RW_navigation-button-case">
            <Button className={`RW_navigation-button`} onClick={rowSelectedDown} disabled={totalSelectedRowCount === 0}>
              <i className="icon-arrow_down" />
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export { RWNavigation };
