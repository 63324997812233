import * as auth0 from 'auth0-js';
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from 'uuid';
import { Events, logEvent } from '..//service/LoggingService';

const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

const getLogout = (setAccessToken: (accessToken: undefined) => void,
  setIdToken: (idToken: undefined) => void,
  setProfile: (profile: undefined) => void,
  setIsPersistingUrl: (arg: boolean) => void,
  persistUrl: boolean) => {
  return () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('scopes');
    localStorage.removeItem('id_token');
    setIsPersistingUrl(persistUrl);
    setAccessToken(undefined);
    setIdToken(undefined);
    setProfile(undefined);
  };
};

const login = (auth0: any) => {
  auth0.authorize({
    prompt: 'login'
  })
};

const isAuthenticated = () => {
  const expiresAt = getLocalStorageItem('expires_at');
  if (expiresAt === null) {
    return false;
  } else {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAtDate = JSON.parse(expiresAt);
    const localDate = new Date().getTime();
    return localDate < expiresAtDate;
  }
};

const decodeToken = (token: string) => {
  return jwt_decode(token);
};

// Set profile state and local storage at the same time
const setSProfile = (setProfile: (profile: Record<string, any>) => void, profile: Record<string, any>) => {
  localStorage.setItem('profile', JSON.stringify(profile));
  setProfile(profile);
};

const getUserInfo = (auth0: any, accessToken: string, setProfile: (profile: Record<string, any>) => void) => {
  auth0.client.userInfo(accessToken, (err: any, profile: any) => {
    if (profile) {
      setSProfile(setProfile, profile);
    }
    if (err) {
      console.error(err);
    }
  });
};

const setSession = (auth0: any, authResult: any, setAccessToken: (accessToken: string) => void, setIdToken: (idToken: string) => void, setProfile: (profile: Record<string, any>) => void) => {
  const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
  const idToken = authResult.idToken;
  const accessToken = authResult.accessToken;

  const scopes = JSON.stringify(authResult.scope);

  localStorage.setItem('access_token', accessToken);
  localStorage.setItem('expires_at', expiresAt);
  localStorage.setItem('scopes', scopes);
  localStorage.setItem('id_token', idToken);

  const decodedId = decodeToken(idToken) as any;
  const idTokenExp = decodedId.exp;

  if (idTokenExp) {
    localStorage.setItem('id_token_exp', idTokenExp);
  }

  const profile = authResult.idTokenPayload;

  if (profile) {
    setSProfile(setProfile, profile);
  } else {
    getUserInfo(auth0, accessToken, setProfile)
  }
  // Set state variables now that everything else is finished.
  setAccessToken(accessToken);
  setIdToken(idToken);
};

const handleAuthentication = (auth0: any, setAccessToken: (accessToken: string) => void, setIdToken: any, setProfile: (profile: Record<string, any>) => void) => {
  auth0.parseHash((err: any, authResult: any) => {
    if (authResult && authResult.accessToken && authResult.idToken) {
      setSession(auth0, authResult, setAccessToken, setIdToken, setProfile);
    } else {
      login(auth0);
    }
  });
};

const onAppStart = (auth0: any, idToken: string | undefined, profile: Record<string, any> | undefined, sessionId: string | undefined, setAccessToken: (accessToken: string | undefined) => void, setIdToken: (idToken: string | undefined) => void, setProfile: (profile: Record<string, any> | undefined) => void, setSessionId: (idToken: string | undefined) => void) => {
  if (isAuthenticated()) {
    if (!idToken) {
      const storedIdToken = getLocalStorageItem('id_token');
      if (storedIdToken === null) {
        console.error('onInit :: rejected as idToken is not available');
      } else {
        setIdToken(storedIdToken);
      }
    }

    if (!profile) {
      const storedProfile = getLocalStorageItem('profile');
      if (storedProfile === null) {
        console.error('onInit :: rejected as profile is not available');
      } else {
        const oldProfile = JSON.parse(storedProfile);
        setProfile(oldProfile);
      }
    }
  } else {
    handleAuthentication(auth0, setAccessToken, setIdToken, setProfile);
  }
  if (idToken && profile && sessionId === undefined) {
    const newSessionId = uuidv4();
    logEvent(idToken, profile, Events.AppStart, {
      session_id: newSessionId
    });
    setSessionId(newSessionId);
  }
};

const requestedScopes = 'openid email userId tenant roles administrativeRoles forceChangePassword';
const MAX_TOLERABLE_TIME_OFFSET = 60;

const newAuth0 = () => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const clientID = process.env.REACT_APP_AUTH0_CLIENTID || '';
  const redirectUri = window.location.origin || process.env.REACT_APP_AUTH0_REDIRECTURI || '';

  return new auth0.WebAuth({
    domain,
    clientID,
    redirectUri,
    responseType: 'token id_token',
    scope: requestedScopes,
    leeway: MAX_TOLERABLE_TIME_OFFSET
  })
}

export const Auth = {
  onAppStart,
  getLogout,
  newAuth0,
  isAuthenticated
};
