import { ServiceBase } from "./ServiceBase";

enum Events {
    AppStart = 'AppStart',
    NavigateToView = 'NavigateToView',
    NavigateToViewByDrilldown = 'NavigateToViewByDrilldown',
    NavigateBackToView = 'NavigateBackToView',
    FilterGridFromPageTopFilters = 'FilterGridFromPageTopFilters',
    RemoveFilterFromGridFromPageTopFilters = 'RemoveFilterFromGridFromPageTopFilters',
    FilterChange = 'FilterChange',
    RemoveFilterFromChip = 'RemoveFilterFromChip',
    SortChange = 'SortChange'
}

const insertLog = (token: string, log: any) => {
    const datasourceApi = process.env.REACT_APP_DATASOURCE_API_URL;
    const url = `${datasourceApi}/log`;
    const contentType = 'application/json';
    const content = JSON.stringify(log);
    return ServiceBase.authPut(url, token, contentType, content);
};

const ApplicationName = 'Fugro.Roames.Analytics'

const logEvent = (token: string, profile: any, event: String, extra?: any) => {
    // TODO: Put this in the above if statement when we have a service
    const {email, tenant} = profile;
    const log_extra_data = extra ? JSON.stringify(extra) : undefined;
    if (process.env.REACT_APP_SERVER_LOGGING === 'true') {
        insertLog(token, {
            log_event: event,
            log_application: ApplicationName,
            log_user_id: email,
            log_tenant: tenant,
            log_extra_data
        })
    } else {
        const consoleTemplate = `Event: ${event}
        Application: ${ApplicationName}
        User Id: ${email}
        Tenant: ${tenant}
        Extra: ${JSON.stringify(extra)}`;

        console.log(consoleTemplate)
    }
};

export {
    logEvent,
    Events
}