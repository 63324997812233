const downloadFile = (data: any, fileName: string, fileType: string) => {
  let blob = new Blob([data], { type: 'csv' }),
    url = window.URL.createObjectURL(blob),
    a = document.createElement('a');

  a.href = url;
  a.download = `${fileName}.${fileType.toLowerCase()}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export default downloadFile