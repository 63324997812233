import React from "react";
import { Tooltip } from "@mui/material";
import { TOOLTIP_TIMING } from "../../../constants";

interface SignoutProps {
  signOut: () => void;
}

const Signout = ({ signOut }: SignoutProps) => {
  return (
    <div className="signout" onClick={signOut}>
      <Tooltip title={"Logout"} key={"tooltip_logout_button"} placement="right" enterDelay={TOOLTIP_TIMING.Enter} leaveDelay={TOOLTIP_TIMING.Leave}>
        <div className="signout__icon icon-sign_out"></div>
      </Tooltip>
    </div>
  );
};

export { Signout };
