import React, { useContext, useRef, useState } from "react";

import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import { IPageTopFiltersRef, PageTopFilters } from './PageTopFilters';
import AppContext from '../../context/AppContext';
import { Favorite, ModelView } from '../../model/View';
import { Slug } from "./Slug";
import { IToolboxRef, Toolbox } from "./Toolbox";
import Toast from "../common/Toast";
import { AvailableNavigations } from './AvailableNavigations';
import { PastNavigation, PastNavigations } from './PastNavigations';
import { ViewsState } from '../../components/viewlist/ViewCategory';
import { Favorites } from './Favorites';
import { isNullOrUndefined } from "../../utils/helper";
import { Auth } from "../../auth/Auth";
import { Navbar } from '../common/Navbar';
import { defaultCycleCurrentInd } from "./GridFiltering";
import { AgGrid } from "./AgGrid";
import { AgGridReact } from "ag-grid-react";
import { ExportProgressDialog, IExportProgressDialogRef } from "./dialogs/ExportProgressDialog";
import { FavoriteDialog, IFavoriteDialogRef } from "./dialogs/FavoriteDialog";
import { IFileNameDialogContainerRef } from "./dialogs/file-name-dialog/FileNameDialogInterfaces";
import { FileNameDialogContainer } from "./dialogs/file-name-dialog/FileNameDialogContainer";
import { IValueUpdateBinRef, ValueUpdateBin } from "./ValueUpdateBin";

interface GridProps {
  profile: any;
  view: ModelView;
  setView: (view: ModelView) => void;
  filterModel: any;
  navigateBackToView: (index: number) => void;
  views: ViewsState;
  navigations: PastNavigation[];
  navigateToView: (navigations: PastNavigation[]) => void;
  sortModel: any[];
  columnState: any;
  selectedFavorite: Favorite;
  setSelectedFavorite: (a: any) => void;
}

const Grid = ({
  profile,
  view,
  setView,
  navigateBackToView,
  views,
  navigations,
  navigateToView,
  filterModel,
  sortModel,
  columnState,
  selectedFavorite,
  setSelectedFavorite
}: GridProps) => {

  const { appIdToken, appAccessToken, appProfile, appIsPersistingUrl, appToast, appSessionId } = useContext(AppContext);
  const logOut = Auth.getLogout(appAccessToken.set, appIdToken.set, appProfile.set, appIsPersistingUrl.set, true);
  const token = appIdToken.get();
  const sessionId = appSessionId.get();

  const [pagination, setPagination] = useState(false); // Pagination=False (Infinite scroll mode on) as default 

  const pageTopFiltersRef = useRef<IPageTopFiltersRef>(null);
  const exportProgressDialogRef = useRef<IExportProgressDialogRef>(null);
  const toolboxRef = useRef<IToolboxRef>(null);
  const valueUpdateBinRef = useRef<IValueUpdateBinRef>(null);
  const favouriteDialogRef = useRef<IFavoriteDialogRef>(null);
  const fileDialogRef = useRef<IFileNameDialogContainerRef>(null);

  const gridRef = useRef<AgGridReact>(null);

  const preFilter = navigations.length > 0 ? navigations[navigations.length - 1].preFilter : {};

  const setFavorite = (value: any) => {
    if (gridRef.current !== null) {
      setSelectedFavorite(value);
      if (!isNullOrUndefined(value)) {
        if (!isNullOrUndefined(value.Customisation.sort)) {
          gridRef.current.columnApi.applyColumnState({
            state: value.Customisation.sort,
            defaultState: { sort: null }
          });
        }
        if (!isNullOrUndefined(value.Customisation.filter)) {
          gridRef.current.api.setFilterModel(value.Customisation.filter);
        }
        if (!isNullOrUndefined(value.Customisation.columnState)) {
          gridRef.current.columnApi.applyColumnState({
            state: value.Customisation.columnState
          });
        }
      } else {
        gridRef.current.columnApi.applyColumnState({
          defaultState: { sort: null }
        });
        // If we reset the grid back to the default filter model, should we have this set to true or not?
        gridRef.current.api.setFilterModel({
          cycle_current_ind: defaultCycleCurrentInd
        });
      }
    }
  };

  const getViewTags = (view: ModelView | null): string => {
    return isNullOrUndefined(view) ? '' : (() => {
      const tags = view.tags !== undefined && view.tags !== null && view.tags.length > 0 ? view.tags : ['Other'];
      return tags.join(' / ');
    })();
  };

  return <div className='app-content'>
    <Navbar
      title='ANALYTICS'
      subtitle={getViewTags(view)}
      children={
        <Favorites
          favouriteDialogRef={favouriteDialogRef}
          values={view.favorites || []}
          selected={selectedFavorite}
          onChange={(event: any, value: Favorite) => { setFavorite(value) }}
        />
      }
    />
    <div className='gridparent'>
      <div>
        <ExportProgressDialog exportProgressDialogRef={exportProgressDialogRef} />
      </div>
      <div className="infobar">
        <Slug view={view} />
        <div className="navigations">
          <PastNavigations navigations={navigations} navigateBackToView={navigateBackToView} />
          <AvailableNavigations view={view} gridRef={gridRef} navigations={navigations} views={views} navigateToView={navigateToView} />
        </div>
      </div>
      <PageTopFilters
        profile={profile}
        sessionId={sessionId} 
        token={token}
        logOut={logOut}
        view={view}
        gridRef={gridRef}
        pageTopFiltersRef={pageTopFiltersRef}
        setToast={appToast.set} />
      {
        // Unfortunately ag-grid is too smart for it's own good and needs to be killed every once in a while
        // If the dirty state is set to true, we need to rerender
        // The dirty state is set to true when pagination is toggled or when the current view no longer matches the view handle (we've changed views)
        <AgGrid
        
          profile={profile}
          sessionId={sessionId}
          pagination={pagination}
          gridRef={gridRef}
          valueUpdateBinRef={valueUpdateBinRef}
          toolboxRef={toolboxRef}
          pageTopFiltersRef={pageTopFiltersRef}
          exportProgressDialogRef={exportProgressDialogRef}
          fileDialogRef={fileDialogRef}
          token={token}
          view={view}
          navigations={navigations}
          navigateToView={navigateToView}
          appToast={appToast}
          views={views}
          logOut={logOut}
          sortModel={sortModel}
          filterModel={filterModel}
          preFilter={preFilter}
          columnState={columnState}
        />
      }
      <ValueUpdateBin
        view={view}
        profile={profile}
        token={token}
        gridRef={gridRef}
        valueUpdateBinRef={valueUpdateBinRef}
        setToast={appToast.set} />
      <Toolbox
        gridRef={gridRef}
        toolboxRef={toolboxRef}
        token={token}
        view={view}
        pagination={pagination}
        setPagination={setPagination}
        setToast={appToast.set}
        logOut={logOut} />
      <FileNameDialogContainer fileDialogRef={fileDialogRef} />
      <FavoriteDialog
        favouriteDialogRef={favouriteDialogRef}
        selectedFavorite={selectedFavorite}
        setFavorite={setFavorite}
        setSelectedFavorite={setFavorite}
        view={view}
        setView={setView}
        token={token}
        gridRef={gridRef} />
      <Toast toastAttrs={appToast.get()} setToastAttrs={appToast.set} />
    </div>
  </div>;
}

export {
  Grid
}
