import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MAX_ROWS_EXPORT } from '../../../../constants';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { FileNameDialogAttributes } from './FileNameDialogInterfaces';


interface FileNameValidation {
  isError: boolean;
  reason?: string;
}

const FileNameDialog = ({ open, fileTypeOptions, totalRowCount, submitCallback }: FileNameDialogAttributes) => {
  const [fileName, setFileName] = useState<string>("export");
  const [fileType, setFileType] = useState<string>("");
  const [fieldErrorMessage, setFieldErrorMessage] = useState('');

  const onExport = () => {
    handleCloseButton(true);
  };

  const onCancel = () => {
    handleCloseButton(false);
  }

  const onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    handleCloseButton(false);
  };

  const handleCloseButton = (downloadFile: boolean) => {
    if (downloadFile) {
      submitCallback({ fileName: fileName, fileType: fileType, shouldFileDownload: true });
    } else {
      submitCallback({ shouldFileDownload: false });
    }
  };

  useEffect(() => {
    if (fileTypeOptions?.length > 0) {
      setFileType(fileTypeOptions[0])
    }
  }, [])

  const validateFileName = (fileName: string): FileNameValidation => {
    const fileNameTokens = fileName.split('.');
    if (fileNameTokens.length >= 2) {
      return {
        isError: true,
        reason: 'Multiple file extensions not allowed'
      }
    }

    return { isError: false }
  }

  const maxRowCountForExport: number = MAX_ROWS_EXPORT[fileType as keyof typeof MAX_ROWS_EXPORT]
  const exportCountWarning: string = `Export size for ${fileType} files in ROAMES Analytics is limited to ${maxRowCountForExport} rows (the exported table contains ${totalRowCount} rows). 
  For bulk export please contact customer support team.`

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Export to selected file type...</DialogTitle>
      <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                error={fieldErrorMessage !== ''}
                autoFocus
                id="fileName"
                label="File Name"
                value={fileName}
                helperText={fieldErrorMessage}
                onChange={(event) => {
                  const fileValidation = validateFileName(event.target.value);
                  if (fileValidation.isError) {
                    setFieldErrorMessage(fileValidation.reason == null ? 'error in file namae' : fileValidation.reason);

                  } else {
                    if (fieldErrorMessage !== '') {
                      setFieldErrorMessage('')
                    }
                    setFileName(event.target.value);
                  }
                }}
              />
              </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="file-type-select-label" >File Type</InputLabel>
                <Select
                  labelId="file-type-select-label"
                  id="fileType"
                  value={fileType}
                  label="File Type"
                  onChange={(event) => { setFileType(event.target.value as string) }}
                > {fileTypeOptions.map((o) => (
                  <MenuItem
                    key={o}
                    value={o}
                  >
                    {o === 'XLSX' ? 'Excel' : o}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {
            totalRowCount > maxRowCountForExport ?
              <div className="warning-text">
                {exportCountWarning}
              </div>
              :
              <></>
          }
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onExport} color="primary">
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export {
  FileNameDialog
}
