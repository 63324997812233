import React from 'react';
import { ModelView } from '../model/View';


export interface AppContextInterface {
  appView: {
    get: () => ModelView;
    set: (value: ModelView) => void;
  },
  appErrorMessage: {
    get: () => any,
    set: () => {},
  }
}

const AppContext = React.createContext<any>({
  appView: {
    get: () => undefined,
    set: () => {},
  },
  appErrorMessage: {
    get: () => undefined,
    set: () => {},
  }
});

export default AppContext;