import React, { useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Auth } from '../../../../auth/Auth';
import { DEFAULT_FAVORITE_ID } from '../../../../constants';
import AppContext from '../../../../context/AppContext';
import { Favorite, ModelView } from '../../../../model/View';
import { ViewCustomisationService } from '../../../../service/ViewCustomisationService';
import { ViewService } from '../../../../service/ViewService';
import { isNullOrUndefined } from '../../../../utils/helper';
import { Grid } from '../../../grid/Grid';
import { defaultCycleCurrentInd } from '../../../grid/GridFiltering';
import { ViewsState } from '../../../viewlist/ViewCategory';

interface ViewHandleContentRoutesProps {
  views: ViewsState;
  profile: any;
  navigations: any[]
  navigateBackToView: (index: number) => void;
  navigateToView: (navigations: any[]) => void;
  view: ModelView | null;
  setView: (view: ModelView | null) => void;
  filterModel: any | null;
  setFilterModel: (filterModel: any) => void;
  sortModel: any[] | null;
  setSortModel: (sortModel: any[]) => void;
  columnState: any[] | null;
  setColumnState: (columnState: any[]) => void;
  selectedFavorite: any;
  setSelectedFavorite: (selectedFavorite: any) => void;
  needsRefresh: boolean;
  setNeedsRefresh: (needsRefresh: boolean) => void;
}

const renameDefaultFavorite = (favorites: Favorite[]) => {
  return favorites.map((favorite) => {
    // Rename the default favorite back to "Default"
    return favorite.Id === DEFAULT_FAVORITE_ID ? {
      ...favorite,
      Name: 'Default'
    } : favorite;
  });
};

const cleanFavorites = (viewFavorites: Favorite[], userFavorites: Favorite[]) => {
  // We have some user favorites
  if (userFavorites.length > 0) {
    const userDefault = userFavorites.findIndex((favorite: Favorite) => {
      return favorite.Id === DEFAULT_FAVORITE_ID;
    });
    // We have a user default
    if (userDefault > -1) {
      // Filter out the default favorite from the view favorite
      const viewFavs = viewFavorites.filter((favorite: Favorite) => {
        return favorite.Id !== DEFAULT_FAVORITE_ID;
      });
      return renameDefaultFavorite(viewFavs.concat(userFavorites));
    } else {
      return renameDefaultFavorite(viewFavorites.concat(userFavorites));
    }
  } else {
    return renameDefaultFavorite(viewFavorites);
  }
};

const ViewHandleContentRoutes = ({
  views,
  profile,
  navigations,
  navigateBackToView,
  navigateToView,
  view,
  setView,
  filterModel,
  setFilterModel,
  sortModel,
  setSortModel,
  columnState,
  setColumnState,
  selectedFavorite,
  setSelectedFavorite,
  needsRefresh,
  setNeedsRefresh
}: ViewHandleContentRoutesProps) => {

  const { appIdToken, appAccessToken, appProfile, appIsPersistingUrl } = useContext(AppContext);
  const { params } = useRouteMatch() as any;
  const currentViewHandle = params.viewHandle;

  useEffect(() => {
    // If we have no view yet
    if (!isNullOrUndefined(profile) && (needsRefresh)) {
      // so that after the promises come back and we set the first state variable
      // the effect will run again but will skip this process for the rerenders, only calling the service once
      const logOutMethod = Auth.getLogout(appAccessToken.set, appIdToken.set, appProfile.set, appIsPersistingUrl.set, true);
      const promises = [
        ViewService.getViewDefinition(appIdToken.get(), currentViewHandle, logOutMethod),
        ViewCustomisationService.getViewCustomisation(appIdToken.get(), profile.user_id, currentViewHandle, logOutMethod)
      ];
      Promise.all(promises).then((result: any[]) => {
        // Set this first so we don't try again
        setNeedsRefresh(false);
        // TODO: expiry
        const resource = result[1].length > 0 ? {
          favorites: cleanFavorites(result[0].favorites || [], result[1][0].favorites || [])
        } : {
          favorites: renameDefaultFavorite(result[0].favorites || [])
        };
        const finalView = {
          ...result[0],
          ...resource
        };
        // If any state, for any reason, is currently set to null, wipe out the state with either the default or empty values
        if (filterModel === null || sortModel === null || columnState === null) {
          if (finalView.favorites !== undefined) {
            // Try to find the default favorite
            const defaultFavorite = finalView.favorites.find((value: any) => {
              return value.Id === DEFAULT_FAVORITE_ID;
            });
            if (defaultFavorite) {
              setFilterModel(defaultFavorite.Customisation.filter);
              setSortModel(defaultFavorite.Customisation.sort);
              setColumnState(defaultFavorite.Customisation.columnState);
              setSelectedFavorite(defaultFavorite);
            } else {
              setFilterModel({
                cycle_current_ind: defaultCycleCurrentInd
              });
              setSortModel([]);
              setColumnState([]);
              setSelectedFavorite(null);
            }
          } else {
            setFilterModel({
              cycle_current_ind: defaultCycleCurrentInd
            });
            setSortModel([]);
            setColumnState([]);
            setSelectedFavorite(null);
          }
        }
        setView(finalView);
      });
    }
  }, [profile, currentViewHandle, view, appIdToken,
    setView,
    filterModel,
    setFilterModel,
    sortModel,
    setSortModel,
    columnState,
    setColumnState,
    selectedFavorite,
    setSelectedFavorite,
    appAccessToken.set,
    appIsPersistingUrl.set,
    appProfile.set,
    needsRefresh,
    setNeedsRefresh
  ]);

  return <>
    {
      isNullOrUndefined(view) || filterModel === null || sortModel === null || columnState === null ?
        <div> Loading view...</div>
        : <><Grid
          profile={profile}
          view={view}
          setView={setView}
          views={views}
          filterModel={filterModel}
          navigations={navigations}
          navigateBackToView={navigateBackToView}
          navigateToView={navigateToView}
          sortModel={sortModel}
          columnState={columnState}
          selectedFavorite={selectedFavorite}
          setSelectedFavorite={setSelectedFavorite} />
        </>
    }
  </>;
};

export {
  ViewHandleContentRoutes
}
