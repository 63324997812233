import React from 'react';
interface NavbarProps {
  title: string;
  subtitle: string;
  children?: JSX.Element | JSX.Element[];
}

const Navbar = ({title, subtitle, children}: NavbarProps) => {
  return (
    <nav className='navbar' role="navigation">
      <div className='navbar__details'>
        <span className='name'><span><strong>ROAMES </strong>{title}</span>
          { process.env.REACT_APP_TITLE_TAG ? 
            <span className='name__mode'>
              { process.env.REACT_APP_TITLE_TAG }
            </span> : 
            <></>
          }</span>
        <div className='spacer' />
        <span className='name'><span className='name__subtitle'>{subtitle}</span></span>
      </div>
      {children !== undefined ? children : <></>}
    </nav>
  );
};

export {
  Navbar
};
