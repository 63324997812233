import React, { useState } from 'react';
import { Sidenav } from '../../common/sidenav/Sidenav';
import { Sections } from '../../sections/Sections';
import { Profile } from '../../common/sidenav/User';
import { ModelView } from '../../../model/View';
import { ViewsState } from '../../viewlist/ViewCategory';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { PastNavigation } from '../../grid/PastNavigations';
import ViewList from '../../viewlist/ViewList';
import { Navbar } from '../../common/Navbar';
import { ProtectedRoute } from '../ProtectedRoute';
import { ViewHandleContentRoutes } from './viewhandle/ViewHandleRoutes';
import { ViewType, ASSET_TYPES } from '../../../constants';
import { isNullOrUndefined } from '../../../utils/helper';
import { Events, logEvent } from '../../../service/LoggingService';

interface ViewerProps {
  signOut: () => void;
  token: any;
  profile: Profile;
  sessionId: string;
}

const initialState = {
  data: undefined,
  openCategories: [],
  uncategorizedViews: []
};

const defaultGridState = null;

const Viewer = ({ signOut, token, profile, sessionId }: ViewerProps) => {
  const [expanded, setExpanded] = React.useState(true);
  const [activeSection, setActiveSection] = useState<string | null>(ASSET_TYPES[0].type);
  const [views, setViews] = useState<ViewsState>(initialState);
  const [navigations, setNavigations] = useState<any>([]);
  const [view, setView] = useState<any>(null);
  const [filterModel, setFilterModel] = useState<any | null>(defaultGridState);
  const [sortModel, setSortModel] = useState<any[] | null>(defaultGridState);
  const [columnState, setColumnState] = useState<any[] | null>(defaultGridState);
  const [selectedFavorite, setSelectedFavorite] = useState<any | null>(defaultGridState);
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(true);

  let { url, params } = useRouteMatch() as any;
  const history = useHistory();

  const ensureAssetIsSelectedAsPerUrlPath = () => {
    const section: ViewType | undefined = ASSET_TYPES.find((section: ViewType) => {
      return section.route === params.viewType;
    });

    if (section !== null && section !== undefined) {
      if (section?.type !== activeSection) {
        setActiveSection(section.type)
      }
    }
  }

  if (!isNullOrUndefined(params?.viewType)) {
    ensureAssetIsSelectedAsPerUrlPath();
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const assetSelected = (selectedSection: ViewType) => {
    setViews({
      ...views,
      openCategories: []
    });
    setActiveSection(selectedSection.type);
    setView(null);
    history.push('/' + selectedSection.route )
  }

  // Used when clicking a view in the sidebar
  // We want to reset basically everything back to pristine state
  const resetGridAndSetView = (view: ModelView) => {
    setNavigations([]);
    setSelectedFavorite(null);
    setFilterModel(null);
    setSortModel(null);
    setColumnState(null);
    setView(null);
    setNeedsRefresh(true);
    logEvent(token, profile, Events.NavigateToView, {
      session_id: sessionId,
      view_handle: view.view_handle
    });
    history.push(`/${params.viewType}/${view.view_handle}`);
  };

  const navigateBackToView = (index: number) => {
    const newNavigations = navigations.slice(0, index);
    const previousState = navigations[index].previousState;
    setNavigations(newNavigations);
    // TODO: Should we save this too?
    setSelectedFavorite(null);
    setFilterModel(previousState.filterModel);
    setSortModel(previousState.sortModel);
    setColumnState(previousState.columnState);
    setView(null);
    setNeedsRefresh(true);
    logEvent(token, profile, Events.NavigateBackToView, {
      session_id: sessionId,
      view_handle: previousState.view.view_handle
    });
    history.push(`/${params.viewType}/${previousState.view.view_handle}`);
  };

  const navigateToView = (navigations: PastNavigation[]) => {
    const newNavigation = navigations[navigations.length - 1];
    setNavigations(navigations);
    setSelectedFavorite(null);
    setFilterModel(null);
    setSortModel(null);
    setColumnState(null);
    setView(null);
    setNeedsRefresh(true);
    logEvent(token, profile, Events.NavigateToViewByDrilldown, {
      session_id: sessionId,
      view_handle: newNavigation.view.view_handle
    });
    history.push(`/${params.viewType}/${newNavigation.view.view_handle}`);
  };

  return <>
    <div className="main-content">
      <Sidenav profile={profile} expanded={expanded} toggleExpanded={toggleExpanded}>
        <Sections activateSection={assetSelected} signOut={signOut} />
        <ViewList
          activeSection={activeSection}
          expanded={expanded}
          views={views}
          viewHandle={view?.view_handle}
          setViews={setViews}
          resetGridAndSetView={resetGridAndSetView} />
      </Sidenav>
      <div className="app-body">
        <Switch>
          <ProtectedRoute exact={true} path={`${url}`}>
            <Navbar title='ANALYTICS' subtitle={''} />
            <div className="grid-selection-text">Please Select a View from the Left-Side Menu</div>
          </ProtectedRoute>
          <ProtectedRoute exact={false} path={`${url}/:viewHandle`}>
            <ViewHandleContentRoutes
              views={views}
              profile={profile}
              navigations={navigations}
              navigateBackToView={navigateBackToView}
              navigateToView={navigateToView}
              view={view}
              setView={setView}
              filterModel={filterModel}
              setFilterModel={setFilterModel}
              sortModel={sortModel}
              setSortModel={setSortModel}
              columnState={columnState}
              setColumnState={setColumnState}
              selectedFavorite={selectedFavorite}
              setSelectedFavorite={setSelectedFavorite}
              needsRefresh={needsRefresh}
              setNeedsRefresh={setNeedsRefresh}/>
          </ProtectedRoute>
        </Switch>
      </div>
    </div>
  </>;
};

export {
  Viewer
};