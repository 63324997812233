import React from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/lab/Alert/Alert";
import { AlertTitle } from "@mui/lab";

export interface ToastAttributes {
  open: boolean;
  title: string;
  text: string;
  messageType: "success" | "info" | "warning" | "error" | undefined;
}

interface ToastProps {
  toastAttrs: ToastAttributes;
  setToastAttrs: (arg: ToastAttributes) => void;
}

const Toast = (props: ToastProps) => {
  if (!props.toastAttrs.open) {
    return <></>;
  }

  const handleClose = (event: any, reason?: string) => {
    if (reason !== 'clickaway') {
      const newAttrs = { ...props.toastAttrs, open: false, text: '' }
  
      props.setToastAttrs(newAttrs);
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={props.toastAttrs.open}
      autoHideDuration={12000}
      onClose={handleClose}>
      <Alert onClose={handleClose} variant="filled" severity={props.toastAttrs.messageType}>
        <AlertTitle>
          {props.toastAttrs.title}
        </AlertTitle>
        {props.toastAttrs.text}
      </Alert>
    </Snackbar>
  );
}

export default Toast;