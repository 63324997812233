import React from 'react';
import { ModelView } from '../../model/View';
import Tooltip from '@mui/material/Tooltip';
export interface CategorizedViewsData {
  key: string,
  value: ModelView[];
}

export interface CategorizedViewsProps {
  viewHandle: string | undefined;
  data: CategorizedViewsData;
  resetGridAndSetView: (view: ModelView) => void;
}

export const CategorizedViews = ({viewHandle, data, resetGridAndSetView}: CategorizedViewsProps) => {
  return <div className='views'>
    {
      data.value.map((iiView: ModelView, ii: number) => {
        const handleActive = viewHandle === iiView.view_handle
        const className = handleActive ? 'view view--active' : 'view';
        const onClick = handleActive ? undefined : () => {
          resetGridAndSetView(iiView);
        };
        return <Tooltip title={iiView.description ?? ""} key={"tooltip_" + ii} placement="right" enterDelay={500} leaveDelay={200}>
          <div key={ii} className={className} onClick={onClick}>
          {
            handleActive ? <div className='view__chevron icon-arrow'/> : <></>
          }
            <div className='view__handle'>
              {iiView.display_name}
            </div>
          </div>
        </Tooltip>
      })
    }
  </div>;
};
