import React, { MutableRefObject, useEffect, useState } from 'react';
import { FileNameDialogAttributes, FileNameDialogOutputParams, IFileNameDialogContainerRef } from './FileNameDialogInterfaces';
import { FileNameDialog } from './FileNameDialog';

interface FileNameDialogContainerProps {
  fileDialogRef: MutableRefObject<IFileNameDialogContainerRef | null>
}

const FileNameDialogContainer = ({ fileDialogRef }: FileNameDialogContainerProps) => {

  const [fileDialogAttrs, setFileDialogAttrs] = useState<FileNameDialogAttributes>({
    fileTypeOptions: [] as string[], open: false, totalRowCount: 0, submitCallback: (arg: FileNameDialogOutputParams) => { }
  });
  const handleCloseButton = (params: FileNameDialogOutputParams) => {
    if (params.shouldFileDownload) {
      fileDialogAttrs.submitCallback({ ...params }); // this should start downloading the file
    } else {
      fileDialogAttrs.submitCallback({ shouldFileDownload: false });
    }

    const newAttrs = { ...fileDialogAttrs, open: false }

    setFileDialogAttrs(newAttrs);
  };

  useEffect(() => {
    fileDialogRef.current = { setFileDialogAttrs };
  }, [])

  return (<>
    {
      fileDialogAttrs.open &&
      <FileNameDialog
        open={fileDialogAttrs.open}
        fileTypeOptions={fileDialogAttrs.fileTypeOptions}
        totalRowCount={fileDialogAttrs.totalRowCount}
        submitCallback={handleCloseButton}
      />
    }
  </>
  );
}

export {
  FileNameDialogContainer
}
