import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Auth } from '../../auth/Auth';
import LinearProgress from '@mui/material/LinearProgress';
import AppContext from '../../context/AppContext';

const Landing = () => {
  const { appIdToken, appAccessToken, appProfile, appIsPersistingUrl } = useContext(AppContext);

  useEffect(() => {
    if (!Auth.isAuthenticated()) {
      const logOut = Auth.getLogout(appAccessToken.set, appIdToken.set, appProfile.set, appIsPersistingUrl.set, true);
      logOut();
    }
  })

  if (!Auth.isAuthenticated()) {
    return <div><LinearProgress /></div>
  } else {
    return <Redirect to="/asset-model" />
  }
};

export {
  Landing
};