import React, { MutableRefObject, useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, Tooltip } from "@mui/material";
import { TOOLTIP_TIMING } from "../../constants";
import { IFavoriteDialogRef } from "./dialogs/FavoriteDialog";

interface FavoritesProps {
  favouriteDialogRef: MutableRefObject<IFavoriteDialogRef | null>;
  id?: string;
  values: any[];
  selected: any | null;
  onChange: (event: any, value: any) => void;
}

const Favorites = ({
  favouriteDialogRef,
  id,
  values,
  selected,
  onChange,
}: FavoritesProps) => {
  const [open, setOpen] = useState(false);
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const setFavoriteDialogAttrs = favouriteDialogRef.current !== null ? favouriteDialogRef.current.setFavoriteDialogAttrs : () => {};
  
  return <div className="favorites">
  <Tooltip title="Create your own view" key={"tooltip_create_view_button"} placement="left" arrow enterDelay={TOOLTIP_TIMING.Enter} leaveDelay={TOOLTIP_TIMING.Leave}>
    <Button className = "favorite-button" onClick={() => {setFavoriteDialogAttrs({open: true, editing: false})}} color='primary'><i className = 'favorite-icon-add icon-add_circle_outline'/></Button>
  </Tooltip>
  {
  selected !== null ?
    <Tooltip title="Edit current view" key={"tooltip_edit_view_button"} placement="left" arrow enterDelay={TOOLTIP_TIMING.Enter} leaveDelay={TOOLTIP_TIMING.Leave}>
      <Button className = "favorite-button" onClick={() => {setFavoriteDialogAttrs({open: true, editing: true})}} color='primary'><i className = 'favorite-icon-edit icon-pencil'/></Button>
    </Tooltip>
  : <></>
  }
   <Autocomplete
    id={id}
    style={{
      width: 200,
      marginRight: 10
    }}
    size='small'
    open={open}
    onOpen={onOpen}
    onClose={onClose}
    getOptionLabel={(option) => option.Name}
    isOptionEqualToValue={(option: any, value: any) => option.Name === value.Name}
    onChange={(event, value) => {
      onChange(event, value);
    }}
    options={values}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Favorites"
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    )}
    value={selected}
  />
  </div>
}

export {
  Favorites
}
